import {Component, Prop} from 'vue-property-decorator'
import {VNode} from 'vue'
import {TsxComponent} from '@/components/TsxComponent'
import {MobileIdCredentialsForm, MobileIdCredentialsValidation} from '@/_modules/profile/types'
import {ValidationInstance} from '@/utils/vuelidate-extension'
import InternationalPhoneNumberInput from "@/components/form/InternationalPhoneNumberInput";
import TextInput from "@/components/form/TextInput";

interface Props {
  value: MobileIdCredentialsForm;
  v: ValidationInstance<MobileIdCredentialsValidation>;
  disabled: boolean;
  responseCode?: string;
  busy: boolean;
}

@Component({name: 'MobileId'})
export default class MobileId extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: MobileIdCredentialsForm
  @Prop(Object) public readonly v!: ValidationInstance<MobileIdCredentialsValidation>
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(String) public readonly responseCode?: string
  @Prop(Boolean) public readonly busy!: boolean
  private readonly countries = ['EE', 'LT']

  public render(): VNode {
    return (
      <div>
        <TextInput
          label={this.translation('shared.fld-personal-code')}
          placeholder={this.translation('shared.placeholder-personal-code')}
          invalidMessage={this.buildInvalidMessage(this.v.personalNumber)}
          v-model={this.value.personalNumber}
          disabled={this.disabled}
        />
        <InternationalPhoneNumberInput
          label={this.translation('shared.fld-international-phone-number')}
          value={this.value.phoneNumber!}
          v={this.v.phoneNumber}
          disabled={this.disabled}
          enabledCountries={this.countries}
          busy={this.busy}
        />
        {this.responseCode &&
          <div class="callback-code p-4 p-md-6 mb-3">
            <p class="mb-2">{this.translation('shared.response_control_code')}</p>
            <span class="lead font-weight-bold">{this.responseCode}</span>
          </div>
        }
      </div>
    )
  }
}
