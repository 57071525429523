import {Component, Prop} from "vue-property-decorator";
import {TsxComponent} from "@/components/TsxComponent";
import {VNode} from "vue";
import TextInput from "@/components/form/TextInput";
import {AutoCompleteValue, TextInputType} from "@/constants/Elements";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {EmailCredentialsForm, EmailCredentialsValidation} from "@/_modules/profile/types";
import {BIcon, BIconEye, BIconEyeSlash} from "bootstrap-vue";

export interface Props {
  value: EmailCredentialsForm;
  v: ValidationInstance<EmailCredentialsValidation>;
  disabled: boolean;
  markRequired: boolean;
  inputType: TextInputType;
  onSwitchInputType: () => void;
}

@Component({name: 'EmailPasswordFields', components: {BIcon, BIconEye, BIconEyeSlash}})
export default class EmailPasswordFields extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: EmailCredentialsForm
  @Prop(Object) public readonly v!: ValidationInstance<EmailCredentialsValidation>
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(Boolean) public readonly markRequired!: boolean
  @Prop(String) public readonly inputType!: TextInputType

  public render(): VNode {
    const requiredMark: VNode | null = this.markRequired ? <span>*</span> : null
    return (
      <div class="email-password-fields">
        <TextInput
          type={TextInputType.Email}
          placeholder={this.translation("fld_auth_email_placeholder")}
          v-model={this.value.username}
          invalid-message={this.buildInvalidMessage(this.v.username!)}
          disabled={this.disabled}
        >
          <template slot="label">
            {this.translation("fld_auth_email")}{requiredMark}
          </template>
        </TextInput>
        <TextInput
          placeholder={this.translation("fld_auth_password")}
          type={this.inputType}
          autocomplete={AutoCompleteValue.Off}
          v-model={this.value.password}
          invalid-message={this.buildInvalidMessage(this.v.password!)}
          icon-append={'eye'}
          disabled={this.disabled}
          custom-class="border-right-0"
          append={() => {
            return (
              <div class="input-group-text">
                <b-link onClick={() => this.$emit('switchInputType')}>
                  <b-icon icon={this.inputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                </b-link>
              </div>
            )
          }}
        >
          <template slot="label">
            {this.translation("fld_auth_password")}{requiredMark}
          </template>
        </TextInput>
      </div>
    )
  }
}
