import {Component} from 'vue-property-decorator'
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"
import ErrorSection from "@/components/layout/ErrorSection"

import {BasicErrorHandler} from "@/utils/errorHandler"
import {BackendError} from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants"
import LoadingIndicator from "@/components/LoadingIndicator";
import {Language} from "@/types";
import {appStore} from "@/store";
import {RawLocation} from "vue-router";

class EmailVerificationHandler extends BasicErrorHandler {
  protected async handleBackendError(e: BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.TokenNotFound:
        this.errorMessageKey = "err_email_verification_token_not_found"
        break
      default:
        await super.handleBackendError(e)
    }
  }
}
const emailVerificationHandler = new EmailVerificationHandler()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
export default abstract class extends BackendClientMixin {
  protected abstract readonly completion: (token: string) => Promise<void>
  protected abstract readonly nextPath: () => RawLocation

  public async mounted(): Promise<void> {
    const lang = this.$route.query.lang
    switch (lang) {
      case 'EN':
        appStore.setLocale(Language.ENG)
        break
      case 'ET':
        appStore.setLocale(Language.EST)
        break
      case 'RU':
        appStore.setLocale(Language.RUS)
        break
      default:
        appStore.setLocale(Language.EST)
    }

    const token = this.$route.query.token as string
    if (token !== undefined) {
      await this.withRequest(this.completion(token), emailVerificationHandler)
      await this.$router.push(this.nextPath())
    }
  }

  public render(): VNode {
    return (
      <div>
        {this.busy && <LoadingIndicator />}
        {(!this.busy && this.errorMessageKey !== null) &&
        <b-container fluid="xl" class="email-confirmation mt-6 mt-md-10">
          <b-row class="justify-content-center">
            <b-col cols="12" md="10">
              <ErrorSection messageKey={this.errorMessageKey}/>
            </b-col>
          </b-row>
        </b-container>
        }
      </div>
    )
  }
}