import {VNode} from "vue"
import {Component, Prop, Ref} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from 'vuejs-datepicker'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { en, ee, ru } from 'vuejs-datepicker/dist/locale/index'

import moment from "moment"
import {AppLang} from "@/i18n"
import {momentDateFormat} from "@/constants/ApplicationConfiguration"

import $ from 'jquery'

import {BIconCalendar4Week} from "bootstrap-vue"
import { getDatepickerDisplayDateFormat } from "@/utils/formatters"

interface DatePickerInterface extends HTMLInputElement, Vue {
  isOpen: boolean;
  showCalendar(): void;
}

interface DisabledDates {
  to?: Date;
  from?: Date;
}

const todayStart = moment().startOf('day').toDate()
const todayEnd = moment().endOf('day').toDate()

type ValueType = Date

interface PropsType extends AbstractInputPropsType<ValueType> {
  placeholder?: string;
  noPastDate?: boolean;
  noFutureDate?: boolean;
  hideLabel?: boolean;
  disableEarlyThenDate?: Date;
}

@Component({name: "DatePickerInput", components: {BIconCalendar4Week}})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {
  @Prop(Date) public readonly value?: ValueType
  @Prop(String) public readonly placeholder?: string
  @Prop(Boolean) public readonly noPastDate!: boolean
  @Prop(Boolean) public readonly noFutureDate!: boolean
  @Prop(Boolean) public readonly hideLabel?: boolean
  @Prop(Date) public readonly disableEarlyThenDate?: Date

  @Ref('input') public readonly input!: DatePickerInterface

  public get disabledDates(): DisabledDates | undefined {
    if (this.disableEarlyThenDate) {
      return { to: moment(this.disableEarlyThenDate).endOf('day').toDate() }
    }
    if (!this.noPastDate && !this.noFutureDate) {
      return undefined
    } else if (this.noPastDate && this.noFutureDate) {
      throw Error('It is restricted to set both attributes simultaneously: "noPastDate" & "noFutureDate"')
    } else {
      return this.noPastDate ? { to: todayStart } : { from: todayEnd }
    }
  }

  public get lang(): object {
    switch (this.$i18n.locale) {
      case AppLang.ET:
        return ee
      case AppLang.RU:
        return ru
      default:
        return en
    }
  }

  public focus(): void {
    this.input.focus()
  }

  public onClick(): void {
    this.input.showCalendar()
  }

  private parseDate(d: string): Date| undefined {
    const res = moment(d, momentDateFormat).toDate()
    return isNaN(res.getDate()) ? undefined : res
  }

  public isEmpty(): boolean {
    return this.value === undefined
  }

  public mounted(): void {
    const input = $(this.input.$el).find('input').get(0) as HTMLInputElement // Find input element
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputComponent = this.input.$children[0] as any
    const EscKey = 27
    const EnterKey = 13

    // TODO: This is a dirty hack to work around a bug in external component, but our component must have Date type for value
    inputComponent.parseTypedDate = (event: KeyboardEvent) => { // Substitute function in child component that handles typing
      if ([EscKey, EnterKey].includes(event.keyCode)) {
        input.blur()
        inputComponent.typedDate = null
        return
      }
      // Parse date using formatter and emit input event to update chain of components that depend on parent's reactive value
      const date = this.parseDate(input.value)
      if (date !== undefined && this.value !== date) {
        inputComponent.typedDate = input.value
        inputComponent.$emit('typedDate', date)
      }
    }
    // TODO: Second dirty hack (to fix clearing field on close)
    inputComponent.inputBlurred = () => {
      const date = this.parseDate(input.value)
      inputComponent.typedDate = null
      if (date === undefined) {
        inputComponent.input.value = null
        inputComponent.clearDate()
        this.onInput(undefined)
      }
      inputComponent.$emit('closeCalendar')
    }

    // TODO: This is work-around because component doesn't emit these events
    input.onfocus = this.onFocus
    input.onblur = this.onBlur
  }

  public render(): VNode {
    return (
      <b-form-group
        label-for={this.id}
        description={this.description}
        state={this.state}
        invalid-feedback={this.invalidMessage}
        class="date-picker"
      >
        {!this.hideLabel && <label for={this.id}>{this.$slots.label === undefined ? this.label : this.$slots.label}</label>}
        <DatePicker
          id={this.id}
          name={this.name}
          placeholder={this.placeholder}
          required={this.required}
          disabled={this.disabled}
          value={this.value}
          ref="input"
          monday-first={true}
          language={this.lang}
          disabled-dates={this.disabledDates}
          format={getDatepickerDisplayDateFormat()}
          refName="textInput" // TODO: Is it correct?
          typeable={true}
          bootstrap-styling={true}
          onInput={this.onInput}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          calendar-class='app-datepicker-calendar'
          input-class={!this.disabled ? 'border-right-0' : ''}
        >
          {!this.disabled &&
          <template slot="afterDateInput">
            <span class="input-group-append" onClick={this.onClick}>
              <span class="input-group-text">
                <b-icon-calendar4-week variant="primary" />
              </span>
            </span>
          </template>
          }
        </DatePicker>
      </b-form-group>
    )
  }
}
