import {Component, Prop} from 'vue-property-decorator'
import {VNode} from 'vue'
import {TsxComponent} from '@/components/TsxComponent'
import {AutoCompleteValue, TextInputType} from '@/constants/Elements'
import TextInput from '@/components/form/TextInput'
import {ValidationInstance} from '@/utils/vuelidate-extension'
import {EmailCredentials, EmailCredentialsForm, EmailCredentialsValidation} from '@/_modules/profile/types'
import {BIcon, BIconEye, BIconEyeSlash} from 'bootstrap-vue'

interface Props {
  value: EmailCredentialsForm;
  v: ValidationInstance<EmailCredentialsValidation>;
  inputType: TextInputType;
  onSwitchInputType: () => void;
  disabled: boolean;
}

@Component({name: 'LoginEmail', components: {BIcon, BIconEye, BIconEyeSlash}})
export default class extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: Partial<EmailCredentials>
  @Prop(Object) public readonly v!: ValidationInstance<EmailCredentialsValidation>
  @Prop(String) public readonly inputType!: TextInputType
  @Prop(Boolean)public readonly disabled!: boolean

  public render(): VNode {
    return (
      <div>
        <TextInput
          type={TextInputType.Email}
          placeholder={this.translation("fld_auth_email_placeholder")}
          v-model={this.value.username}
          invalid-message={this.buildInvalidMessage(this.v.username!)}
          disabled={this.disabled}
        >
          <template slot="label">
            {this.translation("fld_auth_email")}<span>*</span>
          </template>
        </TextInput>
        <TextInput
          placeholder={this.translation("fld_auth_password")}
          type={this.inputType}
          autocomplete={AutoCompleteValue.Off}
          v-model={this.value.password}
          invalid-message={this.buildInvalidMessage(this.v.password!)}
          icon-append={'eye'}
          disabled={this.disabled}
          custom-class="border-right-0"
          append={() => {
            return (
              <div class="input-group-text">
                <b-link onClick={() => this.$emit('switchInputType')}>
                  <b-icon icon={this.inputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                </b-link>
              </div>
            )
          }}
        >
          <template slot="label">
            {this.translation("fld_auth_password")}<span>*</span>
          </template>
        </TextInput>
      </div>
    )
  }
}