import {Component, Prop} from "vue-property-decorator";
import {VNode} from "vue";
import Button from "@/components/form/Button";
import {EmailCredentialsForm, EmailCredentialsValidation, UserConfirmation} from "@/_modules/profile/types";
import {buildValidationRules, inferValidationInstance, ValidationInstance, ValidationObject} from "@/utils/vuelidate-extension";
import {required, sameAs} from "vuelidate/lib/validators";
import DataBoundaries from "@/constants/DataBoundaries";
import {Validations} from "vuelidate-property-decorators";
import FormMixin from "@/mixins/FormMixin";
import {TextInputType} from "@/constants/Elements";
import CheckBox from "@/components/form/CheckBox";
import {PrivacyPolicy, TermsAndConditions} from "@/router/routes";
import {SignUpConsents, SignUpConsentsForm} from "@/_modules/profile/views/SignUp";
import EmailPasswordFields from "@/_modules/profile/components/EmailPasswordFields";
import TextInput from "@/components/form/TextInput";
import {BIconArrowLeft, BIconArrowRight, BIconExclamationCircle} from "bootstrap-vue";
import {EventBus} from "@/main";
import {successPayload} from "@/mixins/BackendClientMixin";
import {CustomEvents} from "@/constants/ComponentEvents";
import AppIconLoading from "@/components/icons/AppIconLoading";

export interface Props {
  confirmation: (c: UserConfirmation) => Promise<boolean>;
  back: () => void;
  disabled: boolean;
}

interface FormValidation {
  authMethod: EmailCredentialsValidation;
  consents: ValidationObject<SignUpConsents>;
}

interface Form {
  authMethod: EmailCredentialsForm;
  consents: SignUpConsentsForm;
}

interface ComponentValidation {
  form: FormValidation;
}

enum UserType {
  NewUser = 'new-user',
  ExistUser = 'existing-exist'
}

function emitSuccess(): void {
  EventBus.$emit(CustomEvents.FlashMsg, successPayload('msg_complete_email_verification_via_profile'))
}

@Component({name: 'FirstLoginConfirmation', components: {BIconArrowLeft, BIconArrowRight, BIconExclamationCircle}})
export default class FirstLoginConfirmation extends FormMixin {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  @Prop(Function) public readonly confirmation!: ((c: UserConfirmation) => Promise<boolean>)
  @Prop(Function) public readonly back!: (() => void)
  @Prop(Boolean) public readonly disabled!: boolean

  public form: Form = {
    authMethod: {
      kind: 'email',
      username: undefined,
      password: undefined,
      passwordConfirmation: undefined
    },
    consents: {
      generalTerms: undefined,
      privacyPolicy: undefined
    }
  }
  private inputType: TextInputType = TextInputType.Password

  @Validations()
  public validations(): ComponentValidation {
    return {
      form: {
        authMethod: {
          kind: {},
          username: {required, ...buildValidationRules(DataBoundaries.email)},
          password: this.selectedUserType === UserType.ExistUser ? {required, ...buildValidationRules(DataBoundaries.nonEmpty)} : {},
          passwordConfirmation: {}
        },
        consents: {
          generalTerms: this.selectedUserType === UserType.NewUser ? {required, sameAs: sameAs(() => true)} : {},
          privacyPolicy: this.selectedUserType === UserType.NewUser ? {required, sameAs: sameAs(() => true)} : {}
        }
      }
    }
  }

  private selectedUserType: UserType = UserType.ExistUser

  public async handleNewUser(e: Event): Promise<void> {
    e.preventDefault()
    this.resetClientState()
    if (this.checkValidation(this.$v)) {
      const success = await this.confirmation!({ kind: 'new-user', username: this.form.authMethod.username!})
      if (success) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        setTimeout(emitSuccess, 1000)
      }
    }
  }

  public async handleExistingUser(e: Event): Promise<void> {
    e.preventDefault()
    this.resetClientState()
    if (this.checkValidation(this.$v)) {
      await this.confirmation!( { kind: 'existing-user', username: this.form.authMethod.username!, password: this.form.authMethod.password!})
    }
  }

  private switchInputType(): void {
    if (this.inputType === TextInputType.Password) {
      this.inputType = TextInputType.Text
    } else {
      this.inputType = TextInputType.Password
    }
  }

  private handleBack(e: Event): void {
    e.preventDefault()
    this.back()
  }

  public render(): VNode {
    const v: ValidationInstance<ComponentValidation> = inferValidationInstance(this.$v)
    return (
      <form>
        <b-card-body class='first-login-dialog'>
          <div class="d-flex">
            <b-icon-exclamation-circle class="app-icon-lg d-inline-flex mr-3" variant="primary" /><p class="small">{this.translation('shared.title-first-time-login')}</p>
          </div>
          <div class="btn-group w-100 mb-6">
            <Button
              onClick={() => (this.selectedUserType = UserType.ExistUser)}
              variant={this.selectedUserType === UserType.ExistUser ? 'primary' : 'outline-primary'}
              label={this.translation("shared.btn-user-exist")}
              disabled={this.disabled}
            />
            <Button
              onClick={() => (this.selectedUserType = UserType.NewUser)}
              variant={this.selectedUserType === UserType.NewUser ? 'primary' : 'outline-primary'}
              label={this.translation("shared.btn-new-user")}
              disabled={this.disabled}
            />
          </div>
          {this.selectedUserType === UserType.NewUser &&
            <div>
              <TextInput
                name="username"
                placeholder={this.translation("fld_auth_email_placeholder")}
                v-model={this.form.authMethod.username}
                invalid-message={this.buildInvalidMessage(v.form.authMethod.username)}
                disabled={this.disabled}
              >
                <template slot="label">
                  {this.translation("fld_auth_email")}<span>*</span>
                </template>
              </TextInput>
              <div class="consents-block">
                <CheckBox
                  class="mt-2 mb-4"
                  check-box-class="m-0"
                  v-model={this.form.consents.generalTerms}
                  invalid-message={this.buildInvalidMessage(v.form.consents.generalTerms)}
                  disabled={this.busy}
                >
                  <template slot="label">
                    <i18n path="text_general_terms_and_conditions_consent">
                      <b-link
                        to={TermsAndConditions}
                        target="_blank"
                        disabled={this.busy}
                        class="text-primary"
                      >
                        {this.translation("text_general_terms_and_conditions_consent_link")}
                      </b-link>
                    </i18n>
                  </template>
                </CheckBox>
                <CheckBox
                  class="mb-4"
                  check-box-class="m-0"
                  v-model={this.form.consents.privacyPolicy}
                  invalid-message={this.buildInvalidMessage(v.form.consents.privacyPolicy)}
                  disabled={this.busy}
                >
                  <template slot="label">
                    <i18n path="text_privacy_policy_consent">
                      <b-link
                        to={PrivacyPolicy}
                        target="_blank"
                        disabled={this.busy}
                        class="text-primary"
                      >
                        {this.translation('text_privacy_police_consent_link')}
                      </b-link>
                    </i18n>
                  </template>
                </CheckBox>
              </div>
              <div class="d-flex justify-content-between">
                <Button
                  class="mt-2 mr-4"
                  variant="outline-primary"
                  onClick={this.handleBack}
                >
                  <template slot="label">
                    <b-icon-arrow-left class="mr-2"/>
                    {this.translation(`shared.btn_prev`)}
                  </template>
                </Button>
                <Button
                  class="mt-2"
                  variant="primary"
                  onClick={this.handleNewUser}
                  disabled={this.disabled || this.busy}
                >
                  {(!this.disabled && !this.busy) && (
                    <template slot="label">
                      {this.translation("btn_forward")}
                      <b-icon-arrow-right class="ml-2" />
                    </template>
                  )}
                  {(this.disabled || this.busy) && (
                    <template slot="label">
                      <AppIconLoading />
                    </template>
                  )}
                </Button>
              </div>
            </div>
          }
          {this.selectedUserType === UserType.ExistUser &&
            <div>
              <EmailPasswordFields
                value={this.form.authMethod}
                v={v.form.authMethod}
                disabled={false}
                markRequired={true}
                inputType={this.inputType}
                onSwitchInputType={this.switchInputType}
              />
              <div class="d-flex justify-content-between">
                <Button
                  class="mt-2 mr-4"
                  variant="outline-primary"
                  onClick={this.handleBack}
                >
                  <template slot="label">
                    <b-icon-arrow-left class="mr-2"/>
                    {this.translation(`shared.btn_prev`)}
                  </template>
                </Button>
                <Button
                  class="mt-2"
                  variant="primary"
                  onClick={this.handleExistingUser}
                  disabled={this.disabled || this.busy}
                >
                  {(!this.disabled && !this.busy) && (
                    <template slot="label">
                      {this.translation("btn_forward")}
                      <b-icon-arrow-right class="ml-2" />
                    </template>
                  )}
                  {(this.disabled || this.busy) && (
                    <template slot="label">
                      <AppIconLoading />
                    </template>
                  )}
                </Button>
              </div>
            </div>
          }
        </b-card-body>
      </form>
    )
  }
}
