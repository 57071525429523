import { Component, Prop } from 'vue-property-decorator'
import { VNode } from 'vue'
import { TsxComponent } from '@/components/TsxComponent'
import Button from '@/components/form/Button'
import AppIconLoading from '@/components/icons/AppIconLoading'
import { ButtonType } from '@/constants/Elements'
import { profileStore } from '../store/profile'
import { BIconArrowRight } from 'bootstrap-vue'

interface Props {
  disabled: boolean;
  responseCode?: string;
}

@Component({name: 'SignInControls', components: {BIconArrowRight}})
export default class SignInControls extends TsxComponent<Props> {
  @Prop(Boolean)public readonly disabled!: boolean
  @Prop(String) public readonly responseCode?: string

  private static onRequestCancelByUser(): void {
    profileStore.cancelRequest()
  }

  public render(): VNode {
    return (
      <div class="auth-controls">
        <Button 
          class={`w-100 mr-2 mt-2${this.disabled ? ' opacity-1' : ''}`}
          variant="primary"
          type={ButtonType.Submit}
          disabled={this.disabled}
        >
          {!this.disabled && (
            <template slot="label">
              {this.translation("btn_forward")}
              <b-icon-arrow-right class="ml-2" />
            </template>
          )}
          {this.disabled && (
            <template slot="label">
              <AppIconLoading />
            </template>
          )}
        </Button>
        {this.responseCode &&
          <Button
            class="mt-4 w-100"
            variant="outline-primary"
            label={this.translation('shared.btn-cancel')}
            onClick={() => SignInControls.onRequestCancelByUser()}
          />
        }
      </div>
    )
  }
}