import I18nOptions, {SelectOption} from "@/utils/I18nOptions";
import {Component, Prop} from "vue-property-decorator";
import {TsxComponent} from "@/components/TsxComponent";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {VNode} from "vue";
import SelectInput from "@/components/form/SelectInput";
import {PersonalNumberForm, PersonalNumberValidation} from "@/_modules/profile/types";
import TextInput from "@/components/form/TextInput";
import VueI18n from "vue-i18n";

interface Props {
  value: PersonalNumberForm;
  v: ValidationInstance<PersonalNumberValidation>;
  disabled: boolean;
  enabledCountries?: readonly string[];
}

interface CountryOption {
  countryCode: string;
  textKey: string;
}

const countries: CountryOption[] = [
  {
    countryCode: 'EE',
    textKey: 'shared.fld_personal_code_country_ee'
  },
  {
    countryCode: 'LT',
    textKey: 'shared.fld_personal_code_country_lt'
  },
  {
    countryCode: 'LV',
    textKey: 'shared.fld_personal_code_country_lv'
  }
]

const countryOptions = (i18n: VueI18n, enabledCountries: string[] | undefined): SelectOption<string>[] => {
  const filtered = enabledCountries ? countries.filter(c => enabledCountries?.some(e => e === c.countryCode)) : countries

  return I18nOptions.buildOptions(i18n, filtered.map(o => {
    return {value: o.countryCode, text: o.textKey}
  }))
} 

@Component({name: 'PersonalCodeInput'})
export default class PersonalCodeInput extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: PersonalNumberForm
  @Prop(Object) public readonly v!: ValidationInstance<PersonalNumberValidation>
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(Array) public readonly enabledCountries: string[] | undefined

  public render(): VNode {
    return (
      <div>
        <SelectInput
          label={this.translation('shared.lbl_personal_code_country')}
          options={countryOptions(this.$i18n, this.enabledCountries)}
          v-model={this.value.countryCode}
          invalidMessage={this.buildInvalidMessage(this.v.countryCode)}
          disabled={this.disabled}
        />
        <TextInput
          label={this.translation('shared.fld-personal-code')}
          placeholder={this.translation('shared.placeholder-personal-code')}
          invalidMessage={this.buildInvalidMessage(this.v.personalCode)}
          v-model={this.value.personalCode}
          disabled={this.disabled}
        />
      </div>
    )
  }
}
