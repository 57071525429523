import {Component, Prop} from "vue-property-decorator";
import {EmailVerificationCodeForm, EmailVerificationCodeValidation} from "@/_modules/profile/types";
import {buildValidationRules, inferValidationInstance, ValidationInstance} from "@/utils/vuelidate-extension";
import {VNode} from "vue";
import DataBoundaries from "@/constants/DataBoundaries";
import FormMixin from "@/mixins/FormMixin";
import {Validations} from "vuelidate-property-decorators";
import {profileStore} from "@/_modules/profile/store/profile";
import TextInput from "@/components/form/TextInput";
import Button from "@/components/form/Button";
import {BasicErrorHandler} from "@/utils/errorHandler";
import * as ResponseError from "@/utils/errors";
import {ErrorCodes} from "@/constants/APIconstants";
import {required} from "vuelidate/lib/validators";

class EmailVerificationErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: ResponseError.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.TokenNotFound:
        this.errorMessageKey = 'err_email_verification_code_not_found'
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}

interface FormValidation {
  form: EmailVerificationCodeValidation;
}

@Component({name: 'EmailVerificationCode'})
export default class EmailVerificationCode extends FormMixin {
  @Prop({type: Boolean, required: false, default: false}) public readonly disabled?: boolean
  @Prop({type: Function, required: true}) public readonly completion!: (() => Promise<void>)

  private form: EmailVerificationCodeForm = {
    code: undefined
  }

  @Validations()
  public validations(): FormValidation {
    return {
      form: {
        code: {required, ...buildValidationRules(DataBoundaries.emailCode)}
      }
    }
  }
  private async onClick(e: Event): Promise<void> {
    e.preventDefault()
    this.resetClientState()
    if (this.checkValidation(this.$v)) {
      await this.withRequest(profileStore.emailVerification(this.form.code!), new EmailVerificationErrorHandler())
      return this.completion()
    } else {
      return Promise.resolve()
    }
  }

  private confirmationCodeInputClass: string = ''

  private setConfirmationCodeInputGroupFocus(): void {
    this.confirmationCodeInputClass = 'focused'
  }

  private removeConfirmationCodeInputGroupFocus(): void {
    this.confirmationCodeInputClass = ''
  }

  public render(): VNode {
    const v: ValidationInstance<FormValidation> = inferValidationInstance(this.$v)
    return (
      <div class="email-verification-code-input">
        <TextInput
          onFocus={() => this.setConfirmationCodeInputGroupFocus()}
          onBlur={() => this.removeConfirmationCodeInputGroupFocus()}
          class={this.confirmationCodeInputClass}
          name='verification-code'
          placeholder={this.translation('fld_email_verification_placeholder')}
          v-model={this.form.code}
          invalid-message={this.buildInvalidMessage(v.form.code)}
          disabled={this.disabled}
          append={() =>
            <Button
              variant='primary'
              label={this.translation('btn_send_email_verification')}
              disabled={this.disabled}
              onClick={this.onClick}
            />
          }
        />
      </div>
    );
  }
}
