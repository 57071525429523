import {DateNoTime, GeoLocation, GeoLocationForm, GeoLocationValidation, Name, NameForm, NameValidation, Sex, UserId} from "@/types"
import {RecursivePartial} from "@/utils/typescript-library-extensions";
import {ValidationObject, ValidationRuleSet} from '@/utils/vuelidate-extension'
import {AppLang} from "@/i18n";

type SPassword = string;
type SUsername = string;
type SPasswordConfirmation = string;
type SPersonalCode = string;
type SEmail = string;
type SCountryCode = string;

export interface EmailVerificationCode {
  code: string;
}

export type EmailVerificationCodeForm = Partial<EmailVerificationCode>

export interface EmailVerificationCodeValidation extends ValidationObject<EmailVerificationCode>{
  code: ValidationRuleSet;
}

export interface InternationalPhoneNumber {
  countryCode: SCountryCode;
  prefix: string;
  number: string;
}

export type InternationalPhoneNumberForm = Partial<InternationalPhoneNumber>

export interface PersonalNumber {
  countryCode: 'EE' | 'LT' | 'LV';
  personalCode: SPersonalCode;
}

export type PersonalNumberForm = Partial<PersonalNumber>

export enum AuthenticationMethod {
  Email = "Email",
  SmartId = "SmartId",
  MobileId = "MobileId"
}

export interface EmailCredentialsValidation extends ValidationObject<EmailCredentials>{
  username: ValidationRuleSet;
  password: ValidationRuleSet;
  passwordConfirmation: ValidationRuleSet;
}

export interface PersonalNumberValidation extends ValidationObject<PersonalNumber>{
  countryCode: ValidationRuleSet;
  personalCode: ValidationRuleSet;
}

export interface SmartIdCredentialsValidation extends ValidationObject<SmartIdCredentials>{
  personalNumber: PersonalNumberValidation;
}

export interface InternationalPhoneNumberValidation extends ValidationObject<InternationalPhoneNumber> {
  countryCode: ValidationRuleSet;
  prefix: ValidationRuleSet;
  number: ValidationRuleSet;
}

export interface MobileIdCredentialsValidation extends ValidationObject<MobileIdCredentials> {
  phoneNumber: InternationalPhoneNumberValidation;
  personalNumber: ValidationRuleSet;
}

export interface EmailCredentials {
  kind: 'email';
  username: SUsername;
  password: SPassword;
}

export interface SmartIdCredentials {
  kind: 'smart-id';
  personalNumber: PersonalNumber;
}

export interface MobileIdCredentials {
  kind: 'mobile-id';
  phoneNumber: InternationalPhoneNumber;
  personalNumber: string;
}

export type Credentials = EmailCredentials | SmartIdCredentials | MobileIdCredentials

export interface ConfirmNewUser {
  kind: 'new-user';
  username: SUsername;
}

export interface ConfirmExistingUser {
  kind: 'existing-user';
  username: SUsername;
  password: SPassword;
}

export type UserConfirmation = ConfirmNewUser | ConfirmExistingUser

export interface SignUpMethods {
  email: EmailCredentials;
}

export interface SignUpMethodsForm {
  email: EmailCredentialsForm;
}

export interface AuthenticationMethodsForm {
  email: EmailCredentialsForm;
  smartId: SmartIdCredentialsForm;
  mobileId: MobileIdCredentialsForm;
}

export interface EmailCredentialsForm extends Partial<EmailCredentials> {
  passwordConfirmation?: SPasswordConfirmation;
}

export type SmartIdCredentialsForm = RecursivePartial<SmartIdCredentials>
export type MobileIdCredentialsForm = RecursivePartial<MobileIdCredentials>

export interface ForgotPassword {
  email: SEmail;
}

export interface RestorePasswordToken {
  token: string;
}

export interface RestorePassword {
  token: string;
  newPassword: string;
}

export interface ChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface User {
  id: UserId;
  name: Name;
  email: string;
  emailVerified?: boolean;
  phoneNumber: InternationalPhoneNumber;
  birthDate: DateNoTime;
  sex?: Sex;
  notificationLanguage: AppLang;
  location: GeoLocation;
  verified?: boolean;
}

export interface UserForm extends RecursivePartial<User> {
  phoneNumber: InternationalPhoneNumberForm;
  name: NameForm;
  location: GeoLocationForm;
}

export interface UserValidation extends ValidationObject<User> {
  name: NameValidation;
  location: GeoLocationValidation;
}

export interface Profile {
  name: Name;
  email: string;
  phoneNumber: string;
  birthDate?: DateNoTime;
  sex?: Sex;
  location?: GeoLocation;
}

export interface PaymentSettings {
  iban: string;
}

export interface AccountInfo {
  kind: 'naabrid' | 'id-based';
  verified: boolean;
  locked: boolean;
}

export interface PersonalSettings {
  profile: User;
  payment?: PaymentSettings;
  accounts: AccountInfo[];
}
