import {Component} from 'vue-property-decorator'
import {Validations} from "vuelidate-property-decorators"
import {required, sameAs} from "vuelidate/lib/validators"
import {VNode} from "vue"

import FormMixin from "@/mixins/FormMixin"
import TextInput from "@/components/form/TextInput"
import ErrorSection from "@/components/layout/ErrorSection"

import {AutoCompleteValue, ButtonType, TextInputType} from "@/constants/Elements"
import {buildValidationRules, ValidationFieldSet} from "@/utils/vuelidate-extension"

import {profileStore} from "@/_modules/profile/store/profile"

import * as Routes from "@/_modules/profile/router/routes"

import DataBoundaries from "@/constants/DataBoundaries"

import {BasicErrorHandler} from "@/utils/errorHandler"
import {BackendError} from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants"
import {RestorePassword} from "@/_modules/profile/types";
import {BIcon, BIconEye, BIconEyeSlash} from "bootstrap-vue";
import {appStore} from "@/store";
import {Language} from "@/types";
import ContentSection from '@/components/layout/ContentSection'

interface Form extends Partial<RestorePassword> {
  passwordConfirmation: string | undefined;
}

type FormValidation = ValidationFieldSet<Form>

interface ComponentValidation {
  form: FormValidation;
}

class ResetPasswordErrorHandler extends BasicErrorHandler {
  protected async handleBackendError(e: BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.TokenNotFound:
        this.errorMessageKey = "err_password_reset_token_not_found"
        break
      default:
        await super.handleBackendError(e);
    }
  }
}
const resetPasswordErrorHandler = new ResetPasswordErrorHandler()

@Component({name: "ResetPassword", components: {BIcon, BIconEye, BIconEyeSlash}})
export default class extends FormMixin {

  private newPasswordInputType: TextInputType = TextInputType.Password
  private confirmPasswordInputType: TextInputType = TextInputType.Password

  public form: Form = {
    newPassword: undefined,
    passwordConfirmation: undefined
  }

  @Validations()
  public validation(): ComponentValidation {
    return {
      form: {
        token: {},
        newPassword: {required, ...buildValidationRules(DataBoundaries.newPassword)},
        passwordConfirmation: {required, sameAs: sameAs(() => this.form.newPassword)}
      }
    }
  }

  public async mounted(): Promise<void> {
    const lang = this.$route.query.lang
    switch (lang) {
      case 'EN':
        appStore.setLocale(Language.ENG)
        break
      case 'ET':
        appStore.setLocale(Language.EST)
        break
      case 'RU':
        appStore.setLocale(Language.RUS)
        break
      default:
        appStore.setLocale(Language.EST)
    }
    
    const token = this.$route.query.token as string

    if (token === undefined) {
      this.errorMessageKey = 'err_password_reset_token_not_found'
    } else {
      try {
        await this.withRequest(profileStore.tokenValidation(token), resetPasswordErrorHandler)
        this.form.token = token
      } catch(e) {
        if (this.errorMessageKey === null) {
          console.error("Unexpected error", e)
          this.errorMessageKey = "err_unexpected_error"
        }
      }
    }
  }

  private async onSubmit(e: Event): Promise<void> {
    e.preventDefault()
    if (this.checkValidation(this.$v.form)) {
      const request: RestorePassword = {
        token: this.form.token!,
        newPassword: this.form.newPassword!
      }
      await this.withRequest(profileStore.resetPassword(request as RestorePassword), resetPasswordErrorHandler)
      await this.$router.push({
        name: Routes.Success.name,
        params: {
          title: 'title_forgot_password',
          message: 'msg_change_password_message',
          buttonText: 'btn_go_to_home_page',
          path: Routes.Home.path
        }
      })
    }
  }

  private switchNewPassType(): void {
    if (this.newPasswordInputType === TextInputType.Password) {
      this.newPasswordInputType = TextInputType.Text
    } else {
      this.newPasswordInputType = TextInputType.Password
    }
  }

  private switchConfirmPassType(): void {
    if (this.confirmPasswordInputType === TextInputType.Password) {
      this.confirmPasswordInputType = TextInputType.Text
    } else {
      this.confirmPasswordInputType = TextInputType.Password
    }
  }

  public render(): VNode {
    return (
      <b-container fluid="xl" class="forgot-password mt-6 mt-md-10">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            {this.errorMessageKey === null &&
              <ContentSection>
                <h1 class="text-center">{this.translation('title_reset_password')}</h1>
                <b-form onsubmit={this.onSubmit}>
                  <b-row align-h="center">
                    <b-col cols="12" sm="10" md="7" lg="5" class="px-md-0">
                      <TextInput
                        label={this.translation('fld_change_password_new_password')}
                        type={this.newPasswordInputType}
                        autocomplete={AutoCompleteValue.Off}
                        v-model={this.form.newPassword}
                        invalid-message={this.buildInvalidMessage(this.$v.form!.newPassword!)}
                        icon-append={'eye'}
                        custom-class="border-right-0"
                        append={() => {
                          return (
                            <div class="input-group-text">
                              <b-link onClick={() => this.switchNewPassType()}>
                                <b-icon icon={this.newPasswordInputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                              </b-link>
                            </div>
                          )
                        }}
                      >
                        <template slot="topDescription">
                          {this.translation("fld_auth_password_hint")}
                        </template>
                      </TextInput>
                      <TextInput
                        label={this.translation('fld_change_password_repeat_password')}
                        type={this.confirmPasswordInputType}
                        autocomplete={AutoCompleteValue.Off}
                        v-model={this.form.passwordConfirmation}
                        invalid-message={this.buildInvalidMessage(this.$v.form!.passwordConfirmation!, this.fieldPasswordErrorsMap)}
                        icon-append={'eye'}
                        custom-class="border-right-0"
                        append={() => {
                          return (
                            <div class="input-group-text">
                              <b-link onClick={() => this.switchConfirmPassType()}>
                                <b-icon icon={this.confirmPasswordInputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                              </b-link>
                            </div>
                          )
                        }}
                      />
                      <b-button
                        type={ButtonType.Submit}
                        class="w-100"
                        variant="primary"
                      >
                        {this.translation('btn_confirm')}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ContentSection>
            }
            {this.errorMessageKey !== null &&
              <ErrorSection messageKey={this.errorMessageKey} />
            }
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
