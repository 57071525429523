import { Component, Prop } from "vue-property-decorator"
import { TsxComponent } from "@/components/TsxComponent";
import { VNode } from "vue"
import { Signup as SignupRoute } from '../router/routes'


interface Props {
  disabled: boolean;
}

@Component({name: 'SignupSuggestion'})
export default class extends TsxComponent<Props> {
  @Prop(Boolean) public readonly disabled!: boolean

  public render(): VNode {
    return (
      <i18n tag="p" path="fld_signup_suggestion" class="text-right">
        <b-link
          to={SignupRoute.path}
          disabled={this.disabled}
          class="text-primary"
        >
          {this.translation("fld_auth_register")}
        </b-link>
      </i18n>
    )
  }
}