import {Component} from 'vue-property-decorator'
import {Validations} from 'vuelidate-property-decorators'
import {VNode} from 'vue'

import FormMixin from '@/mixins/FormMixin'

import {buildValidationRules, inferValidationInstance, ValidationInstance, ValidationObject, ValidationRuleSet} from '@/utils/vuelidate-extension'
import {required, sameAs} from 'vuelidate/lib/validators'
import {ButtonType, TextInputType} from '@/constants/Elements'

import * as Routes from '../router/routes'

import {BIconArrowRight, BIconEye} from 'bootstrap-vue'
import {ErrorCodes} from '@/constants/APIconstants'
import DataBoundaries from '@/constants/DataBoundaries'
import {BasicErrorHandler} from '@/utils/errorHandler'
import {BackendError} from '@/utils/errors'
import SignUpEmail from '@/_modules/profile/components/SignUpMethods/SignUpEmail'
import {AuthenticationMethod, EmailCredentials, EmailCredentialsValidation, SignUpMethodsForm} from '@/_modules/profile/types'
import CheckBox from '@/components/form/CheckBox'
import {PrivacyPolicy, TermsAndConditions} from '@/router/routes'
import Button from '@/components/form/Button'
import {profileStore} from '@/_modules/profile/store/profile'
import AppIconLoading from '@/components/icons/AppIconLoading'

interface ComponentValidation {
  form: SignUpFormValidation;
}

export type SignUpConsentsForm = Partial<SignUpConsents>

interface SignUpForm {
  selectedSignUpMethod: AuthenticationMethod;
  signUpMethods: SignUpMethodsForm;
  consents: SignUpConsentsForm;
}
interface SignUpFormValidation {
  selectedSignUpMethod: ValidationRuleSet;
  signUpMethods: SignUpMethodsValidation;
  consents: ValidationObject<SignUpConsents>;
}

export interface SignUpMethodsValidation {
  email: EmailCredentialsValidation;
}

export interface SignUpConsents {
  generalTerms: boolean;
  privacyPolicy: boolean;
}

class SignupErrorHandler extends BasicErrorHandler {
  protected async handleBackendError(e: BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.AccountAlreadyExist:
        this.errorMessageKey = "err_account_already_exist"
        break
      default:
        await super.handleBackendError(e)
    }
  }
}
const signupErrorHandler = new SignupErrorHandler()

function buildSignUpForm(): SignUpForm {
  return {
    selectedSignUpMethod: AuthenticationMethod.Email,
    signUpMethods: {
      email: {
        kind: 'email',
        username: undefined,
        password: undefined,
        passwordConfirmation: undefined
      }
    },
    consents: {
      generalTerms: undefined,
      privacyPolicy: undefined
    }
  }
}

@Component({name: "SignUp", components: {BIconArrowRight, BIconEye}})
export default class SignUp extends FormMixin {

  public inputType: TextInputType = TextInputType.Password
  public confirmPasswordInputType: TextInputType = TextInputType.Password

  public form: SignUpForm = buildSignUpForm()

  @Validations()
  public validations(): ComponentValidation {
    return {
      form: {
        selectedSignUpMethod: {},
        signUpMethods: {
          email: {
            kind: {required},
            username: {required, ...buildValidationRules(DataBoundaries.email)},
            password: {required, ...buildValidationRules(DataBoundaries.newPassword)},
            passwordConfirmation: {required, sameAs: sameAs(() => {
              return this.form.signUpMethods.email.password
            })}
          }
        },
        consents: {
          generalTerms: {required, sameAs: sameAs(() => true)},
          privacyPolicy: {required, sameAs: sameAs(() => true)}
        }
      }
    }
  }

  public async onSubmit(e: Event): Promise<void> {
    e.preventDefault()

    const vForm: ValidationInstance<SignUpFormValidation> = inferValidationInstance(this.$v.form)

    if (this.form.selectedSignUpMethod === AuthenticationMethod.Email) {
      const validationResult = [vForm.signUpMethods!.email, vForm.consents!].flatMap(item => this.checkValidation(item))
      if (!validationResult.some(isValid => !isValid)) {
        try {
          const emailCredentials: EmailCredentials = {
            kind: 'email',
            username: this.form.signUpMethods.email.username!,
            password: this.form.signUpMethods.email.password!
          }
          await this.withRequest(profileStore.signUp(emailCredentials), signupErrorHandler)
          await this.$router.push({
            name: Routes.Success.name,
            params: {
              title: 'title_registration_success_page',
              username: emailCredentials.username!,
              message: 'msg_registration_success_page'
            }
          })
        } catch(err) {
          this.form.signUpMethods.email.password = undefined
          this.form.signUpMethods.email.passwordConfirmation = undefined
          this.resetValidation(this.$v)
        }
      }
    } else {
      console.log('Unexpected sign-up method', this.form.selectedSignUpMethod)
    }
  }

  private switchInputType(): void {
    if (this.inputType === TextInputType.Password) {
      this.inputType = TextInputType.Text
    } else {
      this.inputType = TextInputType.Password
    }
  }

  private switchConfirmPasswordInputType(): void {
    if (this.confirmPasswordInputType === TextInputType.Password) {
      this.confirmPasswordInputType = TextInputType.Text
    } else {
      this.confirmPasswordInputType = TextInputType.Password
    }
  }

  /*private resetFormState(): void {
    this.form = buildSignUpForm()
    this.$v.$reset()
  }

  private onEmailTabClick(): void {
    this.resetFormState()
    this.form.selectedSignUpMethod = AuthenticationMethod.Email
  }*/

  public render(): VNode {
    const v: ValidationInstance<ComponentValidation> = inferValidationInstance(this.$v)
    return (
      <header class="hero signin pt-16 pt-lg-20">
        <b-container fluid="xl" class="signup pb-10 pb-lg-20">
          <b-row>
            <b-col cols="12" lg="5" xl="6" class="mb-8 mt-lg-8">
              <h1>{this.translation("text_login_register")}</h1>
              <p class="lead">{this.translation("text_login_provide")}</p>
            </b-col>
            <b-col cols="12" lg="7" xl="6">
              <b-card>
                <form novalidate onsubmit={(e: Event) => this.onSubmit(e)}>
                  <i18n tag="p" path="fld_auth_user_exists" class="text-right">
                    <b-link
                      to={Routes.Login.path}
                      disabled={this.busy}
                      class="text-primary"
                    >
                      {this.translation("fld_auth_login")}
                    </b-link>
                  </i18n>
                  <SignUpEmail
                    value={this.form.signUpMethods.email}
                    v={v.form.signUpMethods.email}
                    inputType={this.inputType}
                    confirmPasswordInputType={this.confirmPasswordInputType}
                    onSwitchInputType={() => this.switchInputType()}
                    onSwitchConfirmPasswordInputType={() => this.switchConfirmPasswordInputType()}
                    disabled={this.busy}
                  />
                  <div class="consents-block">
                    <CheckBox
                      class="mt-2 mb-4"
                      check-box-class="m-0"
                      v-model={this.form.consents.generalTerms}
                      invalid-message={this.buildInvalidMessage(v.form.consents.generalTerms)}
                      disabled={this.busy}
                    >
                      <template slot="label">
                        <i18n path="text_general_terms_and_conditions_consent">
                          <b-link
                            to={TermsAndConditions}
                            target="_blank"
                            disabled={this.busy}
                            class="text-primary"
                          >
                            {this.translation("text_general_terms_and_conditions_consent_link")}
                          </b-link>
                        </i18n>
                      </template>
                    </CheckBox>
                    <CheckBox
                      class="mb-4"
                      check-box-class="m-0"
                      v-model={this.form.consents.privacyPolicy}
                      invalid-message={this.buildInvalidMessage(v.form.consents.privacyPolicy)}
                      disabled={this.busy}
                    >
                      <template slot="label">
                        <i18n path="text_privacy_policy_consent">
                          <b-link
                            to={PrivacyPolicy}
                            target="_blank"
                            disabled={this.busy}
                            class="text-primary"
                          >
                            {this.translation('text_privacy_police_consent_link')}
                          </b-link>
                        </i18n>
                      </template>
                    </CheckBox>
                  </div>
                  <div class="auth-controls">
                    <Button
                      class={`w-100 mr-2 mt-2${this.busy ? ' opacity-1' : ''}`}
                      variant="primary"
                      type={ButtonType.Submit}
                      disabled={this.busy}
                    >
                      {!this.busy && (
                        <template slot="label">
                          {this.translation("btn_forward")}
                          <b-icon-arrow-right class="ml-2" />
                        </template>
                      )}
                      {this.busy && (
                        <template slot="label">
                          <AppIconLoading />
                        </template>
                      )}
                    </Button>
                  </div>
                </form>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </header>
    )
  }
}
