import {Component} from 'vue-property-decorator'
import {profileStore} from "../store/profile"
import EmailConfirmation from "@/_modules/profile/views/EmailConfirmation";
import * as Routes from "@/_modules/profile/router/routes";
import {RawLocation} from "vue-router";
import {authStore} from "@/store";

@Component({name: "EmailVerification"})
export default class extends EmailConfirmation {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  protected readonly completion: (token: string) => Promise<void> = profileStore.emailVerification
  protected readonly nextPath: () => RawLocation = () => {
    if (authStore.authenticated) {
      return {
        name: Routes.SuccessLogged.name,
        params: {
          title: 'title_verification_email',
          message: 'text_email_verification_success_message',
          buttonText: 'btn_to_profile',
          path: Routes.Profile.path
        }
      }
    } else {
      return {
        name: Routes.Success.name,
        params: {
          title: 'title_verification_email',
          message: 'text_registration_success_message',
          buttonText: 'btn_login',
          path: Routes.Login.path
        }
      }
    }
  }
}
