import {Component} from 'vue-property-decorator'
import {Validations} from "vuelidate-property-decorators"
import {VNode} from 'vue'

import FormMixin from "@/mixins/FormMixin"

import TextInput from "@/components/form/TextInput"
import SelectInput from "@/components/form/SelectInput"
import RadioGroupInput from "@/components/form/RadioGroupInput"
import Button from "@/components/form/Button"
import AddressComponent from "@/components/structures/Address"
import DatePickerInput from "@/components/form/DatePickerInput"
import {BIconArrowRight, BIconCheck2Circle, BIconDownload, BIconLock, BIconQuestionCircleFill} from "bootstrap-vue"

import {profileStore} from "@/_modules/profile/store/profile"
import * as ServicesRoutes from "../../service/router/routes"
import * as DemandsRoutes from "../../demand/router/routes"

import {
  buildValidationRules,
  inferValidationInstance,
  ValidationFieldSet,
  ValidationInstance,
  ValidationObject
} from "@/utils/vuelidate-extension"
import {email, required} from "vuelidate/lib/validators"

import ApplicationConfiguration, {defaultCountry, serverDateFormat} from "@/constants/ApplicationConfiguration"
import {ButtonType} from "@/constants/Elements"
import DataBoundaries, {
  ibanValidator,
  internationalPhoneNumber,
  isEmpty,
  nonCyrillicValidator
} from "@/constants/DataBoundaries"
import {AddressValidation, AuthInfo, GeoLocation, GeoLocationValidation, Name, NameValidation, Sex} from "@/types"
import {
  InternationalPhoneNumberValidation,
  PaymentSettings,
  PersonalSettings,
  User,
  UserForm,
  UserValidation
} from "@/_modules/profile/types"

import moment from "moment"
import {RecursivePartial} from "@/utils/typescript-library-extensions"
import I18nOptions from "@/utils/I18nOptions";
import {appStore, authStore} from "@/store";
import {AppLang} from "@/i18n";
import {convertToI18nLang} from "@/store/app"
import {mapStore} from "@/store/map";
import {EventBus} from "@/main";
import * as ResponseError from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants";
import {BasicErrorHandler} from "@/utils/errorHandler";
import {CustomEvents} from "@/constants/ComponentEvents";
import InternationalPhoneNumberInput from "@/components/form/InternationalPhoneNumberInput";
import EmailVerificationCode from "@/components/form/EmailVerificationCode";
import ContentPage from '@/components/ContentPage'
import AssistEarn from '@/components/AssistEarn'

interface Form extends RecursivePartial<PersonalSettings> {
  profile: UserForm;
  payment: Partial<PaymentSettings>;
}

interface FormValidation extends ValidationObject<Form> {
  profile: UserValidation;
  payment: ValidationFieldSet<PaymentSettings>;
}

interface ComponentValidation {
  form: FormValidation;
}

export class ProfileErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: ResponseError.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.UserVerifiedAndCannotChangePrimaryData:
        this.errorMessageKey = 'err_user_verified_and_cannot_change_primary_data'
        break
      case ErrorCodes.CannotDeleteIBANDueToExistingServices:
        this.errorMessageKey = 'err_cannot_delete_iban_due_to_existing_services'
        break
      case ErrorCodes.AccountAlreadyExist:
        this.errorMessageKey = 'err_account_already_exist'
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const profileErrorHandler = new ProfileErrorHandler()

@Component({name: 'Profile', components: {BIconDownload, BIconLock, BIconArrowRight, BIconCheck2Circle, BIconQuestionCircleFill}})
export default class extends FormMixin {

  public profileExist: boolean = false
  private emailInSettings: string | undefined = undefined

  public form: Form = {
    profile: {
      sex: Sex.Unset,
      name: {
      },
      phoneNumber: {
        countryCode: 'EE',
        prefix: '372',
        number: undefined
      },
      location: {
        address: {
          country: defaultCountry
        }
      }
    },
    payment: {}
  }

  @Validations()
  public validations(): ComponentValidation {
    function isAdult(birthDate: string): boolean {
      const res = moment().diff(moment(birthDate), 'years')
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return res < 18
    }
    return {
      form: {
        profile: {
          id: {},
          sex: {},
          verified: {},
          name: this.nameValidation(),
          phoneNumber: this.internationalPhoneNumberValidation(),
          email: {required, email},
          emailVerified: {},
          birthDate: {
            required,
            adult: (() => !isAdult(this.form.profile.birthDate!))
          },
          notificationLanguage: {required},
          location: this.locationValidation()
        },
        payment: {
          iban: {iban: ibanValidator}
        },
        accounts: []
      }
    }
  }

  public internationalPhoneNumberValidation(): InternationalPhoneNumberValidation {
    return {
      countryCode: {required, internationalPhoneNumber},
      prefix: {internationalPhoneNumber},
      number: {required, internationalPhoneNumber}
    }
  }

  public nameValidation(): NameValidation {
    return {
      first: {
        required,
        ...buildValidationRules(DataBoundaries.name),
        nonCyrillic: nonCyrillicValidator
      },
      last: {
        required,
        ...buildValidationRules(DataBoundaries.name),
        nonCyrillic: nonCyrillicValidator
      },
      middle: {}
    }
  }

  public locationValidation(): GeoLocationValidation {
    return {
      address: this.addressValidation(),
      coordinates: {}
    }
  }

  public addressValidation(): AddressValidation {
    return {
      country: {required},
      cityOrCounty: {required, ...buildValidationRules(DataBoundaries.city)},
      zipCode: {required, ...buildValidationRules(DataBoundaries.zipCode)},
      address: {required, ...buildValidationRules(DataBoundaries.address)}
    }
  }

  private async readSettings(): Promise<void> {
    const settings = await this.withRequest(profileStore.getSettings(), profileErrorHandler)
    const profile = settings.profile

    this.form = {
      profile: {
        id: profile.id,
        sex: profile.sex,
        name: {
          first: profile.name?.first,
          last: profile.name?.last
        },
        phoneNumber: {
          countryCode: profile.phoneNumber !== undefined ? profile.phoneNumber.countryCode : 'EE',
          prefix: profile.phoneNumber !== undefined ? profile.phoneNumber.prefix : '372',
          number:  profile.phoneNumber !== undefined ? profile.phoneNumber.number : undefined
        },
        email: profile.email,
        emailVerified: profile.emailVerified,
        birthDate: profile.birthDate,
        location: {
          address: profile.location?.address !== undefined ? profile.location.address : {
            country: defaultCountry
          },
          coordinates: profile.location?.coordinates
        },
        notificationLanguage: profile.notificationLanguage !== undefined ? profile.notificationLanguage : convertToI18nLang(appStore.locale) as AppLang,
        verified: profile.verified
      },
      payment: {
        iban: settings.payment?.iban
      },
      accounts: settings.accounts
    }
    if (settings.profile.phoneNumber !== undefined) {
      this.profileExist = true
    }
    this.emailInSettings = settings.profile.email
  }

  public mounted(): Promise<void> {
    return this.readSettings()
  }

  private async onSubmit(e: Event): Promise<void> {
    e.preventDefault()
    if (this.checkValidation(this.$v.form)) {
      const profileData = this.form.profile
      //const birthDay: DateNoTime = profileData.birthDay.year + '-' + profileData.birthDay.month + '-' + profileData.birthDay.day
      const profile: User = {
        id: profileData.id!,
        name: profileData.name as Name,
        phoneNumber: {
          prefix: profileData.phoneNumber!.prefix!,
          number: profileData.phoneNumber!.number!,
          countryCode: profileData.phoneNumber!.countryCode!
        },
        email: profileData.email as string,
        emailVerified: profileData.emailVerified ? profileData.emailVerified : false,
        birthDate: profileData.birthDate as string,
        sex: profileData.sex,
        notificationLanguage: profileData.notificationLanguage!.toLocaleUpperCase() as AppLang,
        location: profileData.location as GeoLocation
      }
      const settings: PersonalSettings = {
        profile: profile,
        payment: this.form.payment.iban === undefined || this.form.payment.iban === '' ? undefined : {
          iban: this.form.payment.iban!.split(' ').join('')
        },
        accounts: [] // don't send them back
      }
      const authInfo: AuthInfo = {
        displayName: profileData.name as Name,
        userId: profileData.id!,
        profileCompleted: authStore.authInfo!.profileCompleted
      }

      authStore.setAuthInfo(authInfo)
      await this.withRequest(profileStore.submitSettings(settings), profileErrorHandler)
      mapStore.setPosition(profile.location.coordinates)
      this.toastMessagesPayload.successToastMessageKey = 'msg_profile_success'
      EventBus.$emit(CustomEvents.ToastMsg, this.toastMessagesPayload)
      EventBus.$on(CustomEvents.DismissToast, () => {
        this.toastMessagesPayload.successToastMessageKey = null
      })
      this.profileExist = true
    }
  }

  public async onRedirect(e: Event, routeName: string): Promise<void> {
    if (routeName === 'demand') {
      await this.$router.push({
        path: DemandsRoutes.DemandNew.path
      })
    }
    if (routeName === 'service') {
      await this.$router.push({
        path: ServicesRoutes.ServiceNew.path
      })
    }
  }

  private async changeEmail(): Promise<void> {
    if (this.checkValidation(this.$v.form.profile?.email)) {
      await this.withRequest(profileStore.changeEmail(this.form.profile.email!), profileErrorHandler)
      await this.readSettings()
      this.successMessageKey = 'msg_success_email_verification_sent'
    }
    return Promise.resolve()
  }

  private async resendLink(): Promise<void> {
    if (this.emailInSettings !== this.form.profile.email) {
      await this.changeEmail()
    } else {
      await this.withRequest(profileStore.resendEmailLink(), profileErrorHandler)
      this.successMessageKey = 'msg_success_email_verification_sent'
    }
    this.$bvToast.hide("resend-link-toast")

  }

  private emailVerificationCompleted(): Promise<void> {
    return this.readSettings().then((result) => {
      this.successMessageKey = 'msg_success_email_verification_completed'
      return result
    })
  }

  public render(): VNode {
    const internationalPhoneNumberValidator: ValidationInstance<InternationalPhoneNumberValidation> = inferValidationInstance(this.$v.form.profile!.phoneNumber!)
    const emailVerified = this.form.profile.emailVerified
    const naabridAccountVerified = this.form.accounts?.some(a => a?.kind === 'naabrid' && a?.verified)
    return (
      <ContentPage
        title={this.translation('title_profile_settings')}
        wide-header
      >
        <template slot="lead">
          <p>
            {this.translation('profile_settings_lead')}
          </p>
        </template>
        <template slot="content">
          <form onsubmit={this.onSubmit}>
            <fieldset class="mb-5">
              <legend class="h3 fieldset-title">{this.translation('title_profile_settings_personal_data')}</legend>
              <b-row>
                {emailVerified &&
                  <b-col cols="12" class="mb-6">
                    {naabridAccountVerified &&
                    <Button
                      to='/change_password'
                      variant="outline-primary"
                    >
                      <template slot="label">
                        {this.translation('btn_profile_settings_change_password')}
                        <b-icon-arrow-right class="ml-2" />
                      </template>
                    </Button>
                    }
                    {!naabridAccountVerified &&
                    <Button
                      to='/setup_password'
                      variant="outline-primary"
                    >
                      <template slot="label">
                        {this.translation('btn_profile_settings_setup_password')}
                        <b-icon-arrow-right class="ml-2" />
                      </template>
                    </Button>
                    }
                  </b-col>
                }
                <b-col cols="12" md="6">
                  <TextInput
                    disabled={this.form.profile.verified === true}
                    maxLength={ApplicationConfiguration.defaultInputMaxLength}
                    label={this.translation('fld_profile_settings_user_first_name')}
                    placeholder={this.translation('fld_profile_settings_user_first_name_placeholder')}
                    v-model={this.form.profile.name.first}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.profile!.name!.first!)}
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <TextInput
                    disabled={this.form.profile.verified === true}
                    maxLength={ApplicationConfiguration.defaultInputMaxLength}
                    label={this.translation('fld_profile_settings_user_last_name')}
                    placeholder={this.translation('fld_profile_settings_user_last_name_placeholder')}
                    v-model={this.form.profile.name.last}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.profile!.name!.last!)}
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <TextInput
                    class='profile-email'
                    maxLength={ApplicationConfiguration.defaultInputMaxLength}
                    label={this.translation('fld_profile_settings_user_email')}
                    placeholder={this.translation('fld_profile_settings_user_email_placeholder')}
                    v-model={this.form.profile.email}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.profile!.email!)}
                    readonly={emailVerified}
                    disabled={this.busy}
                    append={() => {
                      const appendElements = [];

                      if (this.emailInSettings !== this.form.profile.email) {
                        appendElements.push(<Button variant='primary' onClick={this.changeEmail} label={this.translation('btn_change_email')}/>);
                      }

                      appendElements.push(
                        <b-input-group-text>
                          {(this.busy || emailVerified) && <b-icon-check2-circle font-scale="1" variant="success" />}
                          {!this.busy && !emailVerified && <b-icon-question-circle-fill id="resend-link-div" animation='fade' variant='danger' />}
                          {!this.busy && !emailVerified && 
                            <b-popover
                              target="resend-link-div"
                              triggers="hover"
                              placement="bottom"
                              container={null}
                              title={this.translation("title_email_not_verified")}
                            >
                              {this.translation("toast_email_not_verified")}
                              <Button class="mt-3" onClick={this.resendLink} label={this.translation("btn_resend_link")} variant="primary" />
                            </b-popover>
                          }
                        </b-input-group-text>);
                      
                      return appendElements;
                    }}
                  />
                  {!emailVerified && <EmailVerificationCode completion={this.emailVerificationCompleted}/>}
                </b-col>
                <b-col cols="12" md="6">
                  <InternationalPhoneNumberInput
                    value={this.form.profile.phoneNumber!}
                    v={internationalPhoneNumberValidator}
                    label={this.translation('fld_profile_settings_user_phone_number')}
                    disabled={this.busy}
                    busy={this.busy}
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <DatePickerInput
                    disabled={this.form.profile.verified === true}
                    noFutureDate
                    label={this.translation('fld_profile_settings_user_date_of_birth')}
                    placeholder={this.translation('fld_profile_settings_user_date_of_birth')}
                    value={this.form.profile.birthDate === undefined ? undefined : new Date(this.form.profile.birthDate)}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.profile!.birthDate!)}
                    onInput={(d: Date) => {
                      this.form.profile.birthDate = moment(d).format(serverDateFormat)
                    }}
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <RadioGroupInput
                    class="mb-3"
                    radioClass="pt-md-3"
                    label={this.translation('fld_profile_settings_sex')}
                    options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Sex), 'enum_sex_type_')}
                    v-model={this.form.profile.sex}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.profile!.sex!)}
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <label>{this.translation('fld_profile_settings_main_lang')}</label>
                  <SelectInput
                    value={this.form.profile.notificationLanguage?.toLocaleLowerCase()}
                    onInput={(v: AppLang) => {
                      this.form.profile.notificationLanguage = v
                    }}
                    options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(AppLang), 'fld_profile_settings_language_')}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.profile!.notificationLanguage!)}
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <TextInput
                    maxLength={ApplicationConfiguration.defaultInputMaxLength}
                    label={this.translation('fld_profile_settings_bank_account_number')}
                    placeholder={this.translation('fld_profile_settings_bank_account_number_placeholder')}
                    v-model={this.form.payment.iban}
                    invalid-message={this.buildInvalidMessage(this.$v.form!.payment!.iban!)}
                    description={this.translation('msg_profile_settings_fld_bank_account_description')}
                  />
                </b-col>
              </b-row>
            </fieldset>
            <AddressComponent
              v-model={this.form.profile.location}
              v={this.$v.form?.profile?.location}
              addressAlreadyDefined={!Object.values(this.form.profile.location.address).some((it) => {
                return isEmpty(it)
              })}
            />
            <Button type={ButtonType.Submit} variant="primary" class="mb-md-4">
              <template slot="label">
                {this.translation('btn_save_profile')}
              </template>
            </Button>
          </form>
        </template>
        {this.profileExist && 
          <template slot="append">
            <AssistEarn />
          </template>
        }
      </ContentPage>
    )
  }
}
