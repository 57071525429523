import { Component } from "vue-property-decorator"
import { VNode } from "vue"
import ContentSection from "./layout/ContentSection"
import * as DemandsRoutes from "@/_modules/demand/router/routes"
import * as ServiceRoutes from "@/_modules/service/router/routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import { I18nComponent } from "./I18nComponent"
import LinkedCard from "@/components/layout/LinkedCard"

@Component({name: 'AssistEarn'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentSection class="mt-6">
        <h2 class="text-center">
          {this.translation('title_assist_earn')}
        </h2>
        <b-row class="row-sm-narrow mb-n2 mb-sm-n4">
          <b-col cols="12" md="6">
            <LinkedCard 
              cardImage={require('@/assets/images/Service/education.jpg')}
              leadText={this.translation('assist_earn_find_assistant_desc')}
              title={this.translation('assist_earn_find_assistant')}
              to={ServiceRoutes.ServiceFind} 
            />
          </b-col>
          <b-col cols="12" md="6">
            <LinkedCard 
              cardImage={require('@/assets/images/Service/establishmentoflife.jpg')}
              leadText={this.translation('assist_earn_become_assistant_desc')}
              title={this.translation('assist_earn_become_assistant')}
              to={DemandsRoutes.DemandFind} 
            />
          </b-col>
          <b-col cols="12" md="6">
            <LinkedCard 
              cardImage={require('@/assets/images/Service/spiritualhealthandemotions.jpg')}
              leadText={this.translation('assist_earn_add_demand_desc')}
              title={this.translation('assist_earn_add_demand')}
              to={DemandsRoutes.DemandNew} 
            />
          </b-col>
          <b-col cols="12" md="6">
            <LinkedCard 
              cardImage={require('@/assets/images/Service/transport.jpg')}
              leadText={this.translation('assist_earn_add_service_desc')}
              title={this.translation('assist_earn_add_service')}
              to={ServiceRoutes.ServiceNew} 
            />
          </b-col>
        </b-row>
        {this.$route.path !== ActivityRoutes.Overview.path &&
          <b-row>
            <b-col cols="12" class="mt-4 mt-sm-6 mt-md-10 d-flex justify-content-center">
              <b-button to={ActivityRoutes.Overview} variant="outline-primary" class="flex-fill flex-sm-grow-0">
                {this.translation('btn_assist_earn_go_to_overview')}
              </b-button>
            </b-col>
          </b-row>
        }
      </ContentSection>
    )
  }
}
