import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import VueMultiSelect from "vue-multiselect"
import TextInput from "@/components/form/TextInput";
import {TsxComponent} from "@/components/TsxComponent";
import {InternationalPhoneNumberForm, InternationalPhoneNumberValidation} from "@/_modules/profile/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {configStore, Country, CountryFullName, shiftPriorityCountriesInFront} from "@/store/config";
import {Language} from "@/types";
import {appStore} from "@/store";

interface Props {
  value: InternationalPhoneNumberForm;
  v: ValidationInstance<InternationalPhoneNumberValidation>;
  label: string;
  disableCountrySelect?: boolean;
  disabled: boolean;
  enabledCountries?: string[];
  busy: boolean;
}

interface CountryCodeOption {
  flagUrl: string;
  prefix: string;
  text: string;
  countryCode: string;
}

function countryNameTranslator(countryName: CountryFullName, locale: Language): string {
  switch (locale) {
    case Language.ENG:
      return countryName.eng
    case Language.RUS:
      return countryName.rus
    case Language.EST:
      return countryName.est
    default:
      return countryName.eng
  }
}

function buildCountryCodeOption(country: Country): CountryCodeOption {
  return {
    countryCode: country.countryCode,
    flagUrl: country!.flagUrl,
    prefix: country!.phoneNumberPrefix[0],
    text: countryNameTranslator(country.fullName, appStore.locale)
  }
}

const byLanguage = (lang: Language): (a: Country, b: Country) => number => {
  return (a: Country, b: Country) => {
    switch (lang) {
      case Language.EST: return a.fullName.est.localeCompare(b.fullName.est)
      case Language.RUS: return a.fullName.rus.localeCompare(b.fullName.rus)
      default: return a.fullName.eng.localeCompare(b.fullName.eng)
    }
  }
}

@Component({name: 'InternationalPhoneNumberInput'})
export default class InternationalPhoneNumberInput extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: InternationalPhoneNumberForm
  @Prop(Object) public readonly v!: ValidationInstance<InternationalPhoneNumberValidation>
  @Prop(String) public readonly label!: string
  @Prop(Boolean) public readonly disableCountrySelect!: boolean
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(Array) public readonly enabledCountries?: string[]
  @Prop(Boolean) public readonly busy!: boolean

  private getEnabledCountries(): Country[] {
    const unfiltered = configStore.countries.slice()
    const countries = shiftPriorityCountriesInFront(unfiltered.sort(byLanguage(appStore.locale)))
    return this.enabledCountries !== undefined ? countries.filter(c => this.enabledCountries?.some(e => e === c.countryCode)) : countries
  }

  private countryCodeOptions(): CountryCodeOption[] {
    const enabled = this.getEnabledCountries();
    return enabled.map(country => buildCountryCodeOption(country))
  }
  
  private selectedCountry: CountryCodeOption|undefined;

  public get state(): boolean | undefined {
    return this.buildInvalidMessage(this.v.number) !== undefined ? false : undefined
  }

  public render(): VNode {
    const countryCodeOptionTemplate = ({ option }: { option: CountryCodeOption }): VNode => {
      return (
        <span class="country-code-option">
          <span class={`flag-icon flag-icon-${option.countryCode.toLowerCase()}`} />
          {option.prefix && `+${option.prefix}`}
        </span>
      )
    }

    this.selectedCountry = this.countryCodeOptions().find((country: CountryCodeOption): boolean => {
      return country.countryCode === this.value.countryCode
    })

    return (
      <b-form-group
        class="international-phone-number-form-group"
        label={this.label}
        state={this.state}
        invalid-feedback={this.buildInvalidMessage(this.v.number) || this.buildInvalidMessage(this.v.countryCode) || this.buildInvalidMessage(this.v.prefix)}
      >
        <b-input-group class={`custom-control-multi-select international-phone-number ${this.buildInvalidMessage(this.v.number) || this.buildInvalidMessage(this.v.countryCode) || this.buildInvalidMessage(this.v.prefix) ? 'is-invalid' : ''}`}>
          <b-input-group-prepend>
            <VueMultiSelect
              multiple={false}
              close-on-select={true}
              preselect-first={true}
              searchable={false}
              show-labels={false}
              class="country-code"
              placeholder=""
              disabled={this.busy || this.disabled || this.disableCountrySelect}
              options={this.countryCodeOptions()}
              v-model={this.selectedCountry}
              onInput={() => {
                this.value.prefix = this.selectedCountry!.prefix
                this.value.countryCode = this.selectedCountry!.countryCode
              }}
              scopedSlots={{
                option: countryCodeOptionTemplate,
                singleLabel: countryCodeOptionTemplate
              }}
            />
          </b-input-group-prepend>
          <TextInput
            v-model={this.value.number}
            disabled={this.disabled}
          />
        </b-input-group>
      </b-form-group>
    )
  }
}
