import {Component} from 'vue-property-decorator'
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import {LogoutPayload, profileStore} from "../store/profile"

import * as Routes from "@/router/routes"
import ContentPage from '@/components/ContentPage'

@Component({name: 'Logout'})
export default class extends BackendClientMixin {

  public async mounted(): Promise<void> {
    await this.withRequest(profileStore.logout(new LogoutPayload(this.$router, Routes.Home.path)))
  }

  public render(): VNode {
    return (
      <ContentPage
        title={this.translation('route_logout')}
      >
        <p>{this.translation('text_logout')}</p>
      </ContentPage>
    )
  }
}