import {Component, Prop} from 'vue-property-decorator'
import {VNode} from "vue"

import {TsxComponent} from "@/components/TsxComponent"
import Button from "@/components/form/Button"

import {Home} from "@/router/routes"

import {BIconArrowRight} from "bootstrap-vue"

interface Props {
  messageKey: string;
  params?: string[];
}

@Component({name: "ErrorSection", components: {BIconArrowRight}})
export default class extends TsxComponent<Props> implements Props {

  @Prop(String) public readonly messageKey!: string
  @Prop(Array) public readonly params?: string[]

  public render(): VNode {
    return (
      <b-card body-class="text-center pt-12 pb-8 pt-md-16 pb-md-12 px-lg-16">
        <h1>{this.translation('title_error_occurred')}</h1>
        <p>{this.translation(this.messageKey, this.params)}</p>
        <Button variant="primary" to={Home.path}>
          <template slot="label">
            {this.translation('btn_home')}<b-icon-arrow-right class="ml-2" />
          </template>
        </Button>
      </b-card>
    )
  }
}