import {Component} from 'vue-property-decorator'
import {profileStore} from "../store/profile"
import EmailConfirmation from "@/_modules/profile/views/EmailConfirmation";
import {RawLocation} from "vue-router";
import * as Routes from "@/_modules/profile/router/routes";

@Component({name: "CompleteRegistration"})
export default class extends EmailConfirmation {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  protected readonly completion: (token: string) => Promise<void> = profileStore.completeRegistration
  protected readonly nextPath: () => RawLocation = () => {
    return {
      name: Routes.Success.name,
      params: {
        title: 'title_complete_registration',
        message: 'text_registration_success_message',
        buttonText: 'btn_login',
        path: Routes.Login.path
      }
    }
  }
}
