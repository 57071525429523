import {Component} from 'vue-property-decorator'
import {Validations} from "vuelidate-property-decorators"
import {required, sameAs} from "vuelidate/lib/validators"
import {VNode} from "vue"

import FormMixin from "@/mixins/FormMixin"
import TextInput from "@/components/form/TextInput"
import {BIcon, BIconEye, BIconEyeSlash} from 'bootstrap-vue'

import {profileStore} from "@/_modules/profile/store/profile"

import * as ProfileRoutes from "../router/routes"

import {buildValidationRules, ValidationFieldSet} from "@/utils/vuelidate-extension"
import {AutoCompleteValue, ButtonType, TextInputType} from "@/constants/Elements"
import {BackendError} from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants"
import DataBoundaries from "@/constants/DataBoundaries"
import {BasicErrorHandler} from "@/utils/errorHandler"
import {ChangePassword} from "../types"
import ContentSection from '@/components/layout/ContentSection'

interface Form extends Partial<ChangePassword> {
  passwordConfirmation?: string;
}

type FormValidation = ValidationFieldSet<Form>

interface ComponentValidation {
  form: FormValidation;
}

class ChangePasswordErrorHandler extends BasicErrorHandler {
  protected async handleBackendError(e: BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.UserAuthorizationFailure:
        this.errorMessageKey = 'err_change_password_incorrect_old_password'
        break
      case ErrorCodes.OperationIsNotSupported:
        this.errorMessageKey = 'err_operation_is_not_supported'
        break
      case ErrorCodes.AccountNotProvisioned:
        this.errorMessageKey = 'err_cannot_change_password_without_naabrid_account'
        break
      default:
        await super.handleBackendError(e);
    }
  }
}
const changePasswordErrorHandler = new ChangePasswordErrorHandler()

@Component({name: 'ChangePassword', components: {BIcon, BIconEye, BIconEyeSlash}})
export default class extends FormMixin {

  private currentPasswordInputType: TextInputType = TextInputType.Password
  private newPasswordInputType: TextInputType = TextInputType.Password
  private confirmPasswordInputType: TextInputType = TextInputType.Password

  public form: Form = {
    oldPassword: undefined,
    newPassword: undefined,
    passwordConfirmation: undefined
  }

  @Validations()
  public validations(): ComponentValidation {
    return {
      form: {
        oldPassword: {required, ...buildValidationRules(DataBoundaries.newPassword)},
        newPassword: {required, ...buildValidationRules(DataBoundaries.newPassword)},
        passwordConfirmation: {required, sameAs: sameAs(() => {
          return this.form.newPassword
        })}
      }
    }
  }

  public async onSubmit(e: Event): Promise<void> {
    e.preventDefault()
    if (this.checkValidation(this.$v.form)) {
      const passwords: ChangePassword = {
        oldPassword: this.form.oldPassword as string,
        newPassword: this.form.newPassword as string
      }
      await this.withRequest(profileStore.changePassword(passwords), changePasswordErrorHandler)
      await this.$router.push({
        name: ProfileRoutes.Success.name,
        params: {
          title: 'msg_change_password_title',
          message: 'msg_change_password_message',
          buttonText: 'btn_go_to_profile_page',
          path: ProfileRoutes.Profile.path
        }
      })
    }
  }

  private switchCurrentPassType(): void {
    if (this.currentPasswordInputType === TextInputType.Password) {
      this.currentPasswordInputType = TextInputType.Text
    } else {
      this.currentPasswordInputType = TextInputType.Password
    }
  }

  private switchNewPassType(): void {
    if (this.newPasswordInputType === TextInputType.Password) {
      this.newPasswordInputType = TextInputType.Text
    } else {
      this.newPasswordInputType = TextInputType.Password
    }
  }

  private switchConfirmPassType(): void {
    if (this.confirmPasswordInputType === TextInputType.Password) {
      this.confirmPasswordInputType = TextInputType.Text
    } else {
      this.confirmPasswordInputType = TextInputType.Password
    }
  }

  public render(): VNode {
    return(
      <b-container fluid="xl" class="change-password mt-6 mt-md-10">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            <ContentSection>
              <h1 class="text-center">{this.translation('title_change_password')}</h1>
              <b-form onsubmit={this.onSubmit}>
                <b-row align-h="center">
                  <b-col cols="12" sm="10" md="7" lg="5" class="px-md-0">
                    <TextInput
                      label={this.translation('fld_change_password_current_password')}
                      type={this.currentPasswordInputType}
                      autocomplete={AutoCompleteValue.Off}
                      v-model={this.form.oldPassword}
                      invalidMessage={this.buildInvalidMessage(this.$v.form!.oldPassword!)}
                      placeholder={this.translation('fld_change_password_current_password_placeholder')}
                      icon-append={'eye'}
                      custom-class="border-right-0"
                      append={() => {
                        return (
                          <div class="input-group-text">
                            <b-link onClick={() => this.switchCurrentPassType()}>
                              <b-icon icon={this.currentPasswordInputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                            </b-link>
                          </div>
                        )
                      }}
                    />
                    <TextInput
                      label={this.translation('fld_change_password_new_password')}
                      type={this.newPasswordInputType}
                      autocomplete={AutoCompleteValue.Off}
                      v-model={this.form.newPassword}
                      invalidMessage={this.buildInvalidMessage(this.$v.form!.newPassword!)}
                      placeholder={this.translation('fld_change_password_new_password_placeholder')}
                      icon-append={'eye'}
                      custom-class="border-right-0"
                      append={() => {
                        return (
                          <div class="input-group-text">
                            <b-link onClick={() => this.switchNewPassType()}>
                              <b-icon icon={this.newPasswordInputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                            </b-link>
                          </div>
                        )
                      }}
                    >
                      <template slot="topDescription">
                        {this.translation("fld_auth_password_hint")}
                      </template>
                    </TextInput>
                    <TextInput
                      label={this.translation('fld_change_password_repeat_password')}
                      type={this.confirmPasswordInputType}
                      autocomplete={AutoCompleteValue.Off}
                      v-model={this.form.passwordConfirmation}
                      invalidMessage={this.buildInvalidMessage(this.$v.form!.passwordConfirmation!, this.fieldPasswordErrorsMap)}
                      placeholder={this.translation('fld_change_password_repeat_password_placeholder')}
                      icon-append={'eye'}
                      custom-class="border-right-0"
                      append={() => {
                        return (
                          <div class="input-group-text">
                            <b-link onClick={() => this.switchConfirmPassType()}>
                              <b-icon icon={this.confirmPasswordInputType === TextInputType.Password ? 'eye' : 'eye-slash'} variant='primary' />
                            </b-link>
                          </div>
                        )
                      }}
                    />
                    <b-button type={ButtonType.Submit} variant="primary" class="w-100">
                      {this.translation('btn_confirm')}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ContentSection>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
