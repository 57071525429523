import {Component} from 'vue-property-decorator'
import {Validations} from "vuelidate-property-decorators"
import {VNode} from "vue"

import FormMixin from "@/mixins/FormMixin"
import TextInput from "@/components/form/TextInput"
import {BIconArrowRight} from "bootstrap-vue";

import {profileStore} from "../store/profile"

import * as Routes from "../router/routes"

import {ButtonType} from "@/constants/Elements"
import {buildValidationRules, ValidationFieldSet} from "@/utils/vuelidate-extension"
import {required} from "vuelidate/lib/validators"
import {ErrorCodes} from "@/constants/APIconstants"
import {BackendError} from "@/utils/errors"
import {BasicErrorHandler} from "@/utils/errorHandler"
import DataBoundaries from "@/constants/DataBoundaries"
import {ForgotPassword} from "../types"
import ContentSection from '@/components/layout/ContentSection'

type Form = Partial<ForgotPassword>

type FormValidation = ValidationFieldSet<ForgotPassword>

interface ComponentValidation {
  form: FormValidation;
}

class ForgotPasswordErrorHandler extends BasicErrorHandler {
  protected async handleBackendError(e: BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.AccountNotFound:
        this.errorMessageKey = "err_incorrect_email_address"
        break
      case ErrorCodes.AccountUnverified:
        this.errorMessageKey = "err_unverified_account_on_reset"
        break
      case ErrorCodes.AccountNotProvisioned:
        this.errorMessageKey = "err_account_without_password_on_reset"
        break
      default:
        await super.handleBackendError(e)
    }
  }
}

const forgotPasswordErrorHandler = new ForgotPasswordErrorHandler()

@Component({name: "ForgotPassword", components: {BIconArrowRight}})
export default class extends FormMixin {

  public form: Form = {

  }

  @Validations()
  public validations(): ComponentValidation {
    return {
      form: {
        email: {required, ...buildValidationRules(DataBoundaries.email)}
      }
    }
  }

  public async onSubmit(e: Event): Promise<void> {
    e.preventDefault()
    if (this.checkValidation(this.$v.form)) {
      const email = this.form as ForgotPassword
      await this.withRequest(profileStore.forgotPassword(email as ForgotPassword), forgotPasswordErrorHandler)
      await this.$router.push({
        name: Routes.Success.name,
        params: {
          title: 'route_forgot_password',
          username: email.email,
          message: 'msg_forgot_password_message'
        }
      })
    }
  }

  public render(): VNode {
    return (
      <b-container fluid="xl" class="forgot-password mt-6 mt-md-10">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            <ContentSection>
              <h1 class="text-center">{this.translation('title_forgot_password')}</h1>
              <form onsubmit={this.onSubmit}>
                <b-row align-h="center">
                  <b-col cols="12" sm="10" md="7" lg="5" class="px-md-0">
                    <TextInput
                      name="username"
                      v-model={this.form.email}
                      invalid-message={this.buildInvalidMessage(this.$v.form!.email!)}
                      description={this.translation('password_recovery_description')}
                    >
                      <template slot="label">
                        {this.translation("fld_auth_email")}<span>*</span>
                      </template>
                      <template slot="topDescription">
                        {this.translation("password_recovery_description")}
                      </template>
                    </TextInput>
                    <b-button
                      type={ButtonType.Submit}
                      disabled={this.busy}
                      class="w-100"
                      variant="primary"
                    >
                      {this.translation("btn_send")}
                    </b-button>
                  </b-col>
                </b-row>
              </form>
            </ContentSection>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
