import {Component, Prop} from 'vue-property-decorator'
import {VNode} from 'vue'
import {TsxComponent} from '@/components/TsxComponent'
import {SmartIdCredentialsForm, SmartIdCredentialsValidation} from '@/_modules/profile/types'
import {ValidationInstance} from '@/utils/vuelidate-extension'
import PersonalCodeInput from '@/components/form/PersonalCodeInput'

interface Props {
  value: SmartIdCredentialsForm;
  v: ValidationInstance<SmartIdCredentialsValidation>;
  disabled: boolean;
  responseCode?: string;
}

@Component({name: 'SmartId'})
export default class SmartId extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: SmartIdCredentialsForm
  @Prop(Object) public readonly v!: ValidationInstance<SmartIdCredentialsValidation>
  @Prop(Boolean) public readonly disabled!: boolean
  @Prop(String) public readonly responseCode?: string

  public render(): VNode {
    return (
      <div>
        <PersonalCodeInput
          value={this.value.personalNumber!}
          v={this.v.personalNumber}
          disabled={this.disabled}
        />
        {this.responseCode &&
          <div class="callback-code">
            {this.responseCode}
          </div>
        }
      </div>
    )
  }
}
