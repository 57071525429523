import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

type ValueType = string | number | string[] | number []

interface PropsType extends AbstractInputPropsType<ValueType> {
  options: object[] | string[];
  valueField?: string;
  textField?: string;
  multiple?: boolean;
  placeholder?: string;
  prepend?: () => VNode;
  append?: () => VNode;
  customClass?: string;
}

@Component({name: "SelectInput"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {
  @Prop([String, Number, Array]) public readonly value?: ValueType
  @Prop({type: Array, required: false, default: () => []}) public readonly options!: object[] | string[]
  @Prop({type: String, required: false, default: 'value'}) public readonly valueField!: string
  @Prop({type: String, required: false, default: 'text'}) public readonly textField!: string
  @Prop({type: String, required: false, default: undefined}) public readonly placeholder?: string
  @Prop(Boolean) public readonly multiple!: boolean
  @Prop(Function) public readonly prepend?: () => VNode
  @Prop(Function) public readonly append?: () => VNode
  @Prop(String) public readonly customClass?: string

  public isEmpty(): boolean {
    return this.value === undefined || this.value === []
  }

  public get bindClass(): string | undefined {
    if (this.prepend !== undefined && this.append !== undefined) {
      return 'input-with-append-prepend'
    } else if (this.prepend !== undefined) {
      return 'input-with-prepend'
    } else if (this.append !== undefined) {
      return 'input-with-append'
    } else {
      return ''
    }
  }

  public render(): VNode {
    // TODO: Plaintext must show selected value as a text
    return (
      <b-form-group
        label-for={this.id}
        label={this.label}
        description={this.description}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        {this.prepend !== undefined &&
          this.prepend()
        }
        <b-form-select
          id={this.id}
          name={this.name}
          required={this.required}
          disabled={this.disabled}
          value={this.value}
          options={this.options}
          placeholder={this.placeholder}
          state={this.state}
          value-field={this.valueField}
          text-field={this.textField}
          multiple={this.multiple}
          onInput={this.onInput}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          class={[this.bindClass, this.customClass]}
        >
          {this.$slots.first}
        </b-form-select>
        {this.append !== undefined &&
          this.append()
        }
      </b-form-group>
    )
  }
}
