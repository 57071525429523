import {Component} from 'vue-property-decorator'
import {Validations} from 'vuelidate-property-decorators'
import {VNode} from 'vue'

import FormMixin from '@/mixins/FormMixin'

import {authStore} from '@/store'
import {CallbackPayload, ConfirmationPayload, LoginPayload, profileStore} from '../store/profile'
import * as Routes from '../router/routes'
import {buildValidationRules, inferValidationInstance, ValidationInstance, ValidationObject, ValidationRuleSet} from '@/utils/vuelidate-extension'
import {TextInputType} from '@/constants/Elements'
import {ErrorCodes} from '@/constants/APIconstants'
import {BasicErrorHandler} from '@/utils/errorHandler'
import * as Error from '@/utils/errors'
import {
  AuthenticationMethod,
  AuthenticationMethodsForm,
  EmailCredentials,
  EmailCredentialsValidation,
  InternationalPhoneNumber,
  MobileIdCredentials, MobileIdCredentialsValidation,
  PersonalNumber,
  SmartIdCredentials,
  SmartIdCredentialsValidation, UserConfirmation
} from '../types'
import LoginEmail from '@/_modules/profile/components/SignInMethods/SignInEmail'
import {required} from 'vuelidate/lib/validators'
import DataBoundaries, {
  personalCodeValidator,
  internationalPhoneNumber,
  ageRestrictionValidator, personalCodeWithCountryValidator
} from '@/constants/DataBoundaries'
import {BIconEnvelope} from 'bootstrap-vue'
import MobileId from '@/_modules/profile/components/MobileId'
import SmartId from '@/_modules/profile/components/SmartId'
import {AuthChallengeStep, ConfirmationStep} from "@/types"
import FirstLoginConfirmation from "@/_modules/profile/components/FirstLoginConfirmation"
import SignupSuggestion from '@/_modules/profile/components/SignupSuggestion'
import SignInControls from './SignInControls'

export class LoginErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.UserNotRegisteredWithAuthenticationProvider:
        this.errorMessageKey = "err_auth_no_account"
        break
      case ErrorCodes.UserHasCancelledAuthorizationProcess:
        this.errorMessageKey = "err_auth_user_cancelled_process"
        break
      case ErrorCodes.UserAuthorizationFailure:
        this.errorMessageKey = "err_auth_user_failed"
        break
      case ErrorCodes.AuthenticationProviderSessionTimedOut:
        this.errorMessageKey = "err_auth_timed_out"
        break
      case ErrorCodes.AuthorizationProcessExpiredOrInvalid:
        this.errorMessageKey = "err_auth_expired_or_invalid"
        break
      case ErrorCodes.AccountIsLocked:
        this.errorMessageKey = "err_auth_account_is_locked"
        break
      case ErrorCodes.UserAlreadyBoundToAnotherIDAccount:
        this.errorMessageKey = "err_auth_second_account_cannot_be_bound"
        break
      case ErrorCodes.UserNotFound:
        this.errorMessageKey = "err_user_not_found"
        break
      case ErrorCodes.AccountNotFoundOrMismatchingPassword:
        this.errorMessageKey = "err_account_not_found_or_password_incorrect"
        break
      case ErrorCodes.AccountUnverified:
        this.errorMessageKey = "err_unverified_account"
        break
      case ErrorCodes.AccountNotProvisioned:
        this.errorMessageKey = "err_account_without_password"
        break
      case ErrorCodes.AccountAlreadyExist:
        this.errorMessageKey = "err_account_already_exist"
        break
      case ErrorCodes.AccountNotFound:
        this.errorMessageKey = "err_account_not_found"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const loginErrorHandler = new LoginErrorHandler()

interface ComponentValidation {
  form: SignInFormValidation;
}

interface SignInForm {
  selectedLoginMethod: AuthenticationMethod;
  signInMethods: AuthenticationMethodsForm;
}

interface LoginMethodsValidation extends ValidationObject<LoginMethods> {
  email: EmailCredentialsValidation;
  smartId: SmartIdCredentialsValidation;
  mobileId: MobileIdCredentialsValidation;
}

interface LoginMethods {
  email: EmailCredentials;
  smartId: SmartIdCredentials;
  mobileId: MobileIdCredentials;
}

interface SignInFormValidation extends ValidationObject<SignInForm> {
  selectedLoginMethod: ValidationRuleSet;
  signInMethods: LoginMethodsValidation;
}

function buildLoginForm(): SignInForm {
  return {
    selectedLoginMethod: AuthenticationMethod.Email,
    signInMethods: {
      email: {
        kind: 'email',
        username: undefined,
        password: undefined
      },
      smartId: {
        kind: 'smart-id',
        personalNumber: {
          countryCode: 'EE',
          personalCode: undefined
        }
      },
      mobileId: {
        kind: 'mobile-id',
        personalNumber: undefined,
        phoneNumber: {
          countryCode: 'EE',
          prefix: '372',
          number: undefined
        }
      }
    }
  }
}

@Component({name: "SignIn", components: {BIconEnvelope}})
export default class SignIn extends FormMixin {

  public inputType: TextInputType = TextInputType.Password
  public form: SignInForm = buildLoginForm()

  private responseCode: { code: string | undefined } = {
    code: undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  private confirmation: ((c: UserConfirmation) => Promise<boolean>) | null = null

  @Validations()
  public validations(): ComponentValidation {
    return {
      form: {
        selectedLoginMethod: {},
        signInMethods: {
          email: {
            kind: {required},
            username: {required, ...buildValidationRules(DataBoundaries.email)},
            password: {required, ...buildValidationRules(DataBoundaries.nonEmpty)},
            passwordConfirmation: {}
          },
          smartId: {
            kind: {required},
            personalNumber: {
              countryCode: {required, personalCode: personalCodeWithCountryValidator},
              personalCode: {required, personalCode: personalCodeWithCountryValidator, adult: ageRestrictionValidator}
            }
          },
          mobileId: {
            kind: {required},
            personalNumber: {required, personalCode: personalCodeValidator, adult: ageRestrictionValidator},
            phoneNumber: {
              countryCode: {required, internationalPhoneNumber},
              prefix: {required, internationalPhoneNumber},
              number: {required, internationalPhoneNumber}
            }
          }
        }
      }
    }
  }

  private switchInputType(): void {
    if (this.inputType === TextInputType.Password) {
      this.inputType = TextInputType.Text
    } else {
      this.inputType = TextInputType.Password
    }
  }

  public mounted(): void {
    const errorCode = this.$route.params.errorCode
    if (errorCode !== undefined) {
      switch (errorCode) {
        case ErrorCodes.SessionExpired:
          this.errorMessageKey = "err_session_expired"
          break
        case ErrorCodes.SessionClosed:
          this.errorMessageKey = "err_session_expired"
          break
        case ErrorCodes.SessionNotFound:
          this.errorMessageKey = "err_session_expired"
          break
        case ErrorCodes.SessionMalformed:
          this.errorMessageKey = "err_session_expired"
          break
        default:
          this.errorMessageKey = "err_unexpected_error"
          break
      }
    }
  }

  public async onSubmit(e: Event): Promise<void> {
    e.preventDefault()

    const vForm: ValidationInstance<SignInFormValidation> = inferValidationInstance(this.$v.form)

    if (this.form.selectedLoginMethod === AuthenticationMethod.Email) {
      if (this.checkValidation(vForm.signInMethods!.email)) {
        try {
          const credentials: EmailCredentials = {
            kind: 'email',
            username: this.form.signInMethods.email.username!,
            password: this.form.signInMethods.email.password!
          }
          await this.performLogin(new LoginPayload(credentials))
        } catch (_) {
          this.form.signInMethods.email.password = undefined
          this.resetValidation(this.$v.form)
        }
      }
    }

    if (this.form.selectedLoginMethod === AuthenticationMethod.MobileId) {
      if (this.checkValidation(vForm.signInMethods.mobileId)) {
        try {
          const mobileIdCredentials: MobileIdCredentials = {
            kind: 'mobile-id',
            phoneNumber: this.form.signInMethods.mobileId.phoneNumber as InternationalPhoneNumber,
            personalNumber: this.form.signInMethods.mobileId.personalNumber as string
          }
          await this.performLogin(new LoginPayload(mobileIdCredentials, this.handleChallengeStep))
        } catch (_) {
          this.responseCode.code = undefined
          this.resetValidation(this.$v.form)
        }
      }
    }

    if (this.form.selectedLoginMethod === AuthenticationMethod.SmartId) {
      if (this.checkValidation(vForm.signInMethods.smartId)) {
        try {
          const smartIdCredentials: SmartIdCredentials = {
            kind: 'smart-id',
            personalNumber: {
              personalCode: this.form.signInMethods.smartId.personalNumber!.personalCode as PersonalNumber['personalCode'],
              countryCode: this.form.signInMethods.smartId.personalNumber!.countryCode as PersonalNumber['countryCode']
            }
          }
          await this.performLogin(new LoginPayload(smartIdCredentials, this.handleChallengeStep))
        } catch (_) {
          this.responseCode.code = undefined
          this.resetValidation(this.$v.form)
        }
      }
    }
  }

  private async redirectToHome(): Promise<void> {
    const initialEntryPoint = this.$route.params.initialEntryPoint
    if (initialEntryPoint && initialEntryPoint !== Routes.Login.path && !authStore.isBeenAuthorized) {
      await this.$router.push({
        name: Routes.Home.name,
        params: {
          initialEntryPoint: initialEntryPoint
        }
      })
      authStore.setBeenAuthorized()
    } else {
      await this.$router.push(Routes.Home.path)
    }
  }

  private async performLogin(payload: LoginPayload): Promise<void> {
    await this.withRequest(profileStore.login(payload), loginErrorHandler)
    await this.redirectToHome()
  }

  private handleChallengeStep(step: AuthChallengeStep, nonce: string): Promise<void> {
    this.responseCode.code = step.challenge.verificationCode
    this.busy = false
    return this.withRequest(profileStore.callback(new CallbackPayload(step, nonce, this.handleConfirmationStep)), loginErrorHandler)
  }

  private handleConfirmationStep(step: ConfirmationStep, nonce: string): Promise<void> {
    this.responseCode.code = undefined
    this.busy = false
    return new Promise<void>((resolve) => { this.confirmation = this.handleConfirmation(step, nonce, resolve) })
  }

  private handleConfirmation(step: ConfirmationStep, nonce: string, resolve: (value?: void | PromiseLike<void>) => void): (c: UserConfirmation) => Promise<boolean> {
    return (c: UserConfirmation) => {
      return this.withRequest(profileStore.confirmation(new ConfirmationPayload(step, nonce, c)), loginErrorHandler)
        .then(
          (info) => {
            resolve(info)
            return true
          },
          () => {
            return false
          }
        )
    }
  }

  private resetFormState(): void {
    this.form = buildLoginForm()
    this.$v.$reset()
  }

  private onEmailTabClick(): void {
    this.resetFormState()
    this.form.selectedLoginMethod = AuthenticationMethod.Email
  }

  private onSmartIdTabClick(): void {
    this.resetFormState()
    this.form.selectedLoginMethod = AuthenticationMethod.SmartId
  }

  private onMobileIdTabClick(): void {
    this.resetFormState()
    this.form.selectedLoginMethod = AuthenticationMethod.MobileId
  }

  private confirmationOnBack(): void {
    this.confirmation = null
  }

  public render(): VNode {
    const v: ValidationInstance<ComponentValidation> = inferValidationInstance(this.$v)
    return (
      <header class="hero signin pt-16 pt-lg-20">
        <b-container fluid="xl" class="login pb-10 pb-lg-20">
          <b-row>
            <b-col cols="12" lg="5" xl="6" class="mb-8 mt-lg-8">
              <h1>{this.translation("text_login_signup")}</h1>
              <p class="lead">{this.translation("text_login_use")}</p>
            </b-col>
            <b-col cols="12" lg="7" xl="6" class="login-signup-frame">
              <b-card no-body>
                {!this.confirmation &&
                  <form novalidate onsubmit={(e: Event) => this.onSubmit(e)}>
                    <b-tabs content-class="card-body" justified nav-wrapper-class="lead">
                      <b-tab onClick={() => this.onEmailTabClick()} title-link-class="d-flex align-items-center justify-content-center text-nowrap">
                        <template slot="title">
                          <b-icon-envelope variant="primary" class="d-none d-sm-block mr-2" />
                          {this.translation("login_nav_item_email")}
                        </template>
                        <SignupSuggestion disabled={this.busy} />
                        <LoginEmail
                          value={this.form.signInMethods.email}
                          v={v.form.signInMethods.email as ValidationInstance<EmailCredentialsValidation>}
                          inputType={this.inputType}
                          onSwitchInputType={() => this.switchInputType()}
                          disabled={this.busy}
                        />
                        <SignInControls disabled={this.busy} responseCode={this.responseCode.code} />
                        <div class="mt-6">
                          <b-link disabled={this.busy} to={Routes.ForgotPassword.path} class="text-primary">
                            {this.translation('fld_auth_forgot_password')}
                          </b-link>
                        </div>
                      </b-tab>
                      <b-tab disabled onClick={() => this.onMobileIdTabClick()} title-link-class="d-flex align-items-center justify-content-center">
                        <template slot="title">
                          <b-img alt={this.translation("login_nav_item_mobileid")} class="auth-method-logo" src={require('@/assets/images/icons/mobileid.svg')}/>
                        </template>
                        <SignupSuggestion disabled={this.busy} />
                        <MobileId
                          value={this.form.signInMethods.mobileId}
                          v={v.form.signInMethods.mobileId}
                          disabled={this.busy}
                          responseCode={this.responseCode.code}
                          busy={this.busy} //TODO: passing as separate prop to append extra class to inputs on busy state
                        />
                        <SignInControls disabled={this.busy} responseCode={this.responseCode.code} />
                      </b-tab>
                      <b-tab disabled onClick={() => this.onSmartIdTabClick()} title-link-class="d-flex align-items-center justify-content-center">
                        <template slot="title">
                          <b-img alt={this.translation("login_nav_item_smartid")} class="auth-method-logo" src={require('@/assets/images/icons/smartid.svg')}/>
                        </template>
                        <SignupSuggestion disabled={this.busy} />
                        <SmartId
                          value={this.form.signInMethods.smartId}
                          v={v.form.signInMethods.smartId}
                          disabled={this.busy}
                          responseCode={this.responseCode.code}
                        />
                        <SignInControls disabled={this.busy} responseCode={this.responseCode.code} />
                      </b-tab>
                    </b-tabs>
                  </form>
                }
                {this.confirmation && <FirstLoginConfirmation confirmation={this.confirmation} back={this.confirmationOnBack}/>}
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </header>
    )
  }
}
